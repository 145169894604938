<template>
  <DefaultLayout title="闪现一下">
    <PostLoading
      v-if="pageLoading"
    />
    <PostError
      v-else-if="postError"
      :custom-header="showHeader"
      :text="postError"
      :status="postErrorStatus"
    />
    <div
      v-else-if="postInfo"
      :class="{'show-header': showHeader}"
      class="p-question-detail"
    >
      <PostHeader
        v-if="showHeader"
        :menu-status="true"
        @menuClick="onHeaderMenuClickHandler"
      >
        <div
          ref="answerDetailHeaderRef"
          :style="headerStyle"
          class="p-question-detail-header"
        >
          <div
            class="p-question-detail-header-text"
          >
            <h4 class="p-question-detail-header-text-main ui-nowrap">
              {{ postInfo.title }}
            </h4>
            <p class="p-question-detail-header-text-desc">
              <span>{{ postInfo ? postInfo.answerCnt : '0' }} 个回答</span>
              <span class="icon-right-arrow" />
            </p>
          </div>
          <div
            v-if="postAction && postAction.ifAnswer"
            @click="$checkAuthDeco(onReviewAnswerClickHandler);"
            class="p-question-detail-header-btn"
          >
            我的回答
          </div>
          <div
            v-else
            @click="$checkAuthDeco(onAnswerClickHandler);onAnswerClickHandlerReport()"
            class="p-question-detail-header-btn"
          >
            写回答
          </div>
        </div>
      </PostHeader>
      <NoticeBar
        v-if="Number(postInfo.status) === 2"
        type="warning"
      >
        问题审核中，仅自己可见，稍后刷新页面查看
      </NoticeBar>
      <NoticeBar
        v-if="Number(postInfo.status) === 4"
        type="error"
      >
        问题审核未通过
      </NoticeBar>
      <NoticeBar
        v-if="Number(postInfo.status) === 7"
        type="error"
      >
        问题被管理员下架
      </NoticeBar>
      <PostTitle
        :title="postInfo.title"
        :flag="postInfo ? Number(postInfo.flag) : 0"
        class="post-title p-question-detail__title"
      />
      <div
        class="p-question-detail__author"
      >
        <Avatar
          :avatar="creatorInfo.iconUrl"
          :account-type="creatorInfo.accountType"
          :vip-type="creatorInfo.vipType"
          @click.native="$checkAppDeco(onAvatarClickHandler);onAvatarClickReport();"
          class="p-question-detail__author-avatar"
          size="xs"
        />
        <div
          @click="$linkToPersonal(creatorInfo.uid)"
          class="p-question-detail__author-nickname"
        >
          {{ creatorInfo.nickName }}
        </div>
        <GroupOwner
          v-if="creatorInfo && creatorInfo.groupOwnerType"
          :group-owner-type="creatorInfo.groupOwnerType"
          class="p-question-detail__author-prestige"
        />
        <!-- 勋章 -->
        <badge-icon
          v-if="creatorInfo.userBadge && !!creatorInfo.userBadge.isShow"
          :user-info="creatorInfo"
          class="p-question-detail__author-prestige"
        />
        <!-- 声望图标 -->
        <PrestigeIcon
          v-if="creatorInfo.renownInfo && creatorInfo.renownInfo.iconUrl"
          :icon-url="creatorInfo.renownInfo.iconUrl"
          class="p-question-detail__author-prestige"
        />
        <OnlineStatus
          v-if="creatorInfo"
          :status="creatorInfo.onlineStatus"
          :game-code="creatorInfo.extend && creatorInfo.extend.teamGameCode"
          class="p-question-detail__author-prestige"
        />
        <div class="p-question-detail__author-other">
          <div v-show="parseInt(postInfo.readCnt) > 0">
            {{ postInfo.readCnt | translateNumToText }}浏览
          </div>
          <span
            v-show="parseInt(postInfo.followCnt) > 0"
            class="p-question-detail__author-dot"
          >·</span>
          <div v-show="parseInt(postInfo.followCnt) > 0">
            {{ postInfo.followCnt | translateNumToText }}关注
          </div>
        </div>
      </div>
      <PostText
        :content="contentComputed"
        :pics="postInfo.pics"
        :channel="postInfo.channel"
        class="p-question-detail__post-text"
        post-main="picture"
      />
      <span class="p-question-detail__ip-region">发布于 {{ postInfo.ipRegionStr || '未知' }}</span>
      <GroupEntry
        v-if="groupInfo && !isFromGroup"
        :type="isFromGroup?'simple':'light'"
        :group-info="groupInfo"
        class="p-question-detail-group-entry"
      />
      <div
        v-if="(groupInfo && isFromGroup) || ($compareVersion('1.2') > -1 && topicInfo && topicInfo.title)"
        class="p-question-detail__group-info"
      >
        <GroupEntry
          v-if="groupInfo && isFromGroup"
          :type="isFromGroup?'simple':'light'"
          :group-info="groupInfo"
        />
        <IconLabel
          v-if="$compareVersion('1.2') > -1 && topicInfo && topicInfo.title"
          :text="topicInfo.title"
          @click.native="$checkAppDeco(onTopicClickHandler, topicInfo.id);onTopicClickHandlerReport();"
          @updated.once="onTopicLabelLayout"
          icon="topic"
          style="max-width:154px"
        />
      </div>
      <div
        ref="questionDetailBtnList"
        class="p-question-detail__btn-list"
      >
        <Button
          v-if="postAction && postAction.ifFollow"
          @click="$checkAuthDeco(onFollowClickHandler);onCancelFollowClickHandlerReport();"
          icon="selected"
          disabled
          clickable
          class="p-question-detail__btn-item"
          size="l"
        >
          已关注
        </Button>
        <Button
          v-else
          @click="$checkAuthDeco(onFollowClickHandler);onFollowClickHandlerReport();"
          class="p-question-detail__btn-item"
          icon="plus"
          size="l"
        >
          关注
        </Button>
        <Button
          @click="$checkAuthDeco(onInviteClickHandler);onInviteClickHandlerReport()"
          class="p-question-detail__btn-item"
          icon="invite"
          size="l"
        >
          邀请回答
        </Button>
        <Button
          v-if="postAction && postAction.ifAnswer"
          @click="$checkAuthDeco(onReviewAnswerClickHandler)"
          class="p-question-detail__btn-item"
          type="secondary"
          icon="review"
          size="l"
        >
          我的回答
        </Button>
        <Button
          v-else
          @click="$checkAuthDeco(onAnswerClickHandler);onAnswerClickHandlerReport();"
          class="p-question-detail__btn-item"
          type="secondary"
          icon="write"
          size="l"
        >
          写回答
        </Button>
      </div>
      <PostWelfare
        v-if="!isApp"
        :gift-number="giftNumber"
        :group-info="groupInfo"
        :welfare-info="welfareInfo"
        class="p-question-detail__welfare"
      />
      <HasInviteList
        v-if="invitedUserList.length > 0"
        :invited-user-list="invitedUserList"
      />
      <div
        v-else
        class="gap"
      />
      <div
        key="invite-answer-list"
        v-if="showAnswerList"
      >
        <div class="p-question-detail__answer-header">
          <h4 class="p-question-detail__answer-title">
            回答 {{ (postInfo.answerCnt > 0 && !hideAnswerList) ? postInfo.answerCnt : '' }}
          </h4>
          <Poptip
            v-if="unCollapsedAnswerCnt > 0 && !hideAnswerList"
            v-model="sortPoptipShow"
            position="bottom-right"
          >
            <div
              @click="onSortBtnClickHandler"
              class="p-question-detail__sort-button"
            >
              <span>{{ currentSortType.text }}</span>
              <span class="p-question-detail__icon-sort" />
            </div>
            <div
              slot="content"
              class="p-question-detail__sort-content"
            >
              <div
                v-for="(item, index) in sortTypeList"
                :key="item.value"
                :class="{'p-question-detail__sort-item--checked': index===currentSortTypeIndex}"
                @click="onSortClickHandler(index)"
                class="p-question-detail__sort-item"
              >
                <span class="p-question-detail__sort-icon" />
                <span class="p-question-detail__sort-text">{{ item.label }}</span>
              </div>
            </div>
          </Poptip>
        </div>
        <div
          v-if="hideAnswerList"
          class="p-question-detail__empty"
        >
          <div class="p-question-detail__empty-bg" />
          <div class="p-question-detail__empty-text">
            该内容被下架啦
          </div>
        </div>
        <div
          v-else-if="!answerLoading && !answerError && allListLength.length === 0"
          class="p-question-detail__empty"
        >
          <div class="p-question-detail__empty-bg" />
          <div class="p-question-detail__empty-text">
            首答者曝光度更高哦，快来抢答吧
          </div>
          <div class="p-question-detail__empty-button">
            <Button
              @click="$checkAuthDeco(onAnswerClickHandler);onAnswerClickHandlerReport();"
              type="primary"
              width="166px"
              size="l"
            >
              写回答
            </Button>
          </div>
        </div>
        <ScrollView
          v-else
          :loading="answerLoading"
          :error="answerError"
          :no-more="listEnd"
          :no-more-text="showListLength >=5 ?'- THE END -': '没有更多内容了'"
          :no-more-footer-style="{
            padding: '25px 0',
            backgroundColor: showListLength >=5 ? '#F7F7F7' : '#FFF'
          }"
          @reachBottom="onReachBottomHandler"
          @bottomErrorClick="onBottomErrorClickHandler"
        >
          <div class="p-question-detail__answer-list">
            <div
              v-for="(item, index) in answerList"
              :key="item.id"
              @click="onAnswerItemClickHandler(item, index)"
            >
              <FeedsItem
                :data="item"
                :group-info="groupInfo"
                @postAction="onItemPostAction($event, item)"
                @onComment="onAnswerItemClickHandler(item, index, true)"
              />
              <div class="gap" />
            </div>
          </div>
          <div
            v-if="postInfo.collapseAnswerCnt > 0"
            @click="collapsedAnswerBarClick"
            class="p-question-detail__collapsed-answer-bar"
          >
            <div class="p-question-detail__collapsed-answer-bar__content">
              {{ postInfo.collapseAnswerCnt }} 个回答被折叠
              <div
                @click.stop="onCollapseTipsIconClickHandler"
                class="content-tips-icon"
              >
                <Icon
                  size="s"
                  name="tips"
                />
              </div>
            </div>
            <Icon
              :name="showCollapsed ? 'up-arrow' : 'down-arrow'"
              size="s"
            />
          </div>
          <div
            v-if="showCollapsed"
            class="p-question-detail__collapsed-answer-list"
          >
            <div
              v-for="(item, index) in collapsedAnswerList"
              :key="item.id"
              @click="onAnswerItemClickHandler(item, index)"
            >
              <FeedsItem
                :data="item"
                @postAction="onItemPostAction($event, item)"
                @onComment="onAnswerItemClickHandler(item, index, true)"
              />
              <div class="gap" />
            </div>
          </div>
        </ScrollView>
      </div>
      <div
        key="invite-answer-tips"
        v-else
      >
        <div class="p-question-detail__invite-answer-tips">
          <div class="p-question-detail__invite-answer-tips-title">
            <Icon
              name="success-green"
              size="l"
            />
            <span class="p-question-detail__invite-answer-tips-title-text">
              提问成功
            </span>
          </div>
          <div class="p-question-detail__invite-answer-tips-title-desc">
            {{ inviteListEmpty ? '分享出去可以更快获得解答哦' : '可以邀请下面的闪友来更快获得解答' }}
          </div>
        </div>
        <template v-if="!inviteListEmpty">
          <div class="p-question-detail__invite-answer-header">
            <div class="p-question-detail__invite-answer-header-text">
              邀请推荐闪友来回答
            </div>
            <div class="flex-space" />
            <div
              @click="$checkAuthDeco(onInviteClickHandler);onInviteListClickHandlerReport();"
              class="p-question-detail__invite-answer-header-btn"
            >
              更多
              <Icon name="right-arrow" />
            </div>
          </div>
          <InviteAnswerList
            :page-size="6"
            :max-size="6"
            @hasInit="hasInitInviteAnswerList"
          />
        </template>
      </div>
      <transition name="slide-in-bottom">
        <div
          v-show="isApp && bottomBtnListStatus"
          class="p-question-detail__bottom-fixed"
        >
          <div
            class="p-question-detail__btn-list bottom-fixed-inner"
          >
            <Button
              v-if="postAction && postAction.ifFollow"
              @click="$checkAuthDeco(onFollowClickHandler);onCancelFollowClickHandlerReport();"
              type="disabled"
              icon="selected"
              size="l"
              disabled
              clickable
              class="p-question-detail__btn-item"
            >
              已关注
            </Button>
            <Button
              v-else
              @click="$checkAuthDeco(onFollowClickHandler);onFollowClickHandlerReport();"
              class="p-question-detail__btn-item"
              icon="plus"
              size="l"
            >
              关注
            </Button>
            <Button
              @click="$checkAuthDeco(onInviteClickHandler);onInviteClickHandlerReport();"
              class="p-question-detail__btn-item"
              icon="invite"
              size="l"
            >
              邀请回答
            </Button>
            <Button
              v-if="postAction && postAction.ifAnswer"
              @click="$checkAuthDeco(onReviewAnswerClickHandler)"
              class="p-question-detail__btn-item"
              type="secondary"
              icon="review"
              size="l"
            >
              我的回答
            </Button>
            <Button
              v-else
              @click="$checkAuthDeco(onAnswerClickHandler);onAnswerClickHandlerReport();"
              class="p-question-detail__btn-item"
              type="secondary"
              icon="write"
              size="l"
            >
              写回答
            </Button>
          </div>
        </div>
      </transition>
      <Dialog
        v-model="cancelFollowDialogStatus"
        :confirm="true"
        @confirm="onCancelFollowHandler"
      >
        <div class="p-question-detail__popup-follow">
          确认取消关注？
        </div>
      </Dialog>
      <Dialog
        v-model="deleteDialogStatus"
        :confirm="true"
        :confirm-text-style="{color: '#303133'}"
        :confirm-text="'确认删除'"
        @confirm="onDeleteDialogConfirmHandler"
      >
        确认要删除吗？
      </Dialog>
      <!-- 折叠答案提示 -->
      <Dialog
        v-model="collapseAnswerTipsShow"
        :confirm="true"
        :confirm-text-style="{color: '#303133'}"
        @confirm="onCollapseAnswerTipsConfirm"
        confirm-text="查看折叠细则"
        cancel-text="知道了"
        content-title="折叠规则"
        content-text="闪现一下问答功能设立的初衷是希望玩家在问答过程中互相帮助，解决游戏中遇到的各种问题。
      我们将折叠过于简短、无实质内容的回答，让优质回答获得更多曝光。如需阅读被折叠的回答，可以点击底部折叠区。"
      />
      <PostAccuse
        v-model="accusePopupStatus"
        :post-main="'picture'"
        :post-info="postInfo"
      />
      <ShareDownload
        v-if="!isApp"
        :channel="'sxnrtiwen'"
      />
    </div>
    <PostError
      v-else
    >
      状态异常: {{ postInfo.status }}
    </PostError>
    <ManageDialog
      v-model="isShowManageDialog"
      :post-info="postInfo"
      :creator-info="creatorInfo"
      :group-info="groupInfo"
      @update="onManageUpdate"
    />
  </DefaultLayout>
</template>

<script>
import { mapState } from 'vuex';
import DefaultLayout from '@/layouts/default/index.vue';
import PostHeader from '@/component/post-header/index.vue';
import PostTitle from '@/component/post-title/index.vue';
import Avatar from '@/ui/avatar/index.vue';
import Button from '@/ui/button/index.vue';
import Dialog from '@/ui/dialog/index.vue';
import PostLoading from '@/component/post-loading/index.vue';
import PostError from '@/component/post-error/index.vue';
import PostWelfare from '@/component/post-welfare/index.vue';
import ShareDownload from '@/component/share-download/index.vue';
import GroupEntry from '@/component/group-entry/index.vue';
import IconLabel from '@/ui/label/icon-label.vue';
import GroupOwner from '@/ui/label/group-owner.vue';
import Poptip from '@/ui/poptip/index.vue';
import ScrollView from '@/ui/scroll-view/index.vue';
import NoticeBar from '@/ui/notice-bar/index.vue';
import PostAccuse from '@/component/post-accuse/index.vue';
import PostText from '@/component/post-text/index.vue';
import * as answerApi from '@/api/answer';
import { getQuestionInvitedUser } from '@/api/question';
import postApi from '@/api/post';
import postDetail from '@/plugin/mixin/postDetail';
import postReportData from '@/plugin/mixin/postReportData';
import {
  postAnswer, setRightButton, showShareDialog, popBack, showShareDialogV2,
  // eslint-disable-next-line no-unused-vars
  send as noticeSend, register as noticeRegister, openWithWebbundle, openView,
  sendGlobalNotification, receiveGlobalNotification,
} from '@/jsbridge/index';
import { reportEnum } from '@/api/report';
import { throttle, isBitTrue } from '@/util/util';
import { logger } from '@/util/logger';
import { getShareUrl } from '@/util/get-base-url';
import PrestigeIcon from '@/ui/label/prestige-icon.vue';
import BadgeIcon from '@/ui/label/badge-icon.vue';
import Icon from '@/ui/icon';
import { checkEnv } from '@/util/browser-env';
import { setH5Share } from '@/util/h5-share';
import InviteAnswerList from '@/component/invite-answer-list/index.vue';
import compareVersion from '@/util/compareVersion';
import aegis from '@/util/aegis';
import FeedsItem from '@/component/feeds-item/index.vue';
import unicodeString from '@/util/unicode-string';
import OnlineStatus from '@/ui/label/online-status.vue';
import { unionList } from '@/util/list';
import ManageDialog from '@/component/post-manage/manage-dialog/index.vue';
import HasInviteList from './component/has-invite-list/index.vue';
import { pageStore, PAGE_STORE_MODULE_NAME, pageMixin } from './store';
import uniOpenUrl from '../../../util/uni-open-url';

// "排序方式：1：最新回复排序，2：最新发布排序，3：按最近修改时间排序，4：智能排序"
const SORT_TYPE_LIST = [
  {
    text: '最热',
    label: '最热回答',
    value: 4,
  },
  {
    text: '最新',
    label: '最新回答',
    value: 2,
  },
];

export default {
  name: 'PQuestionDetail',
  components: {
    OnlineStatus,
    BadgeIcon,
    PostTitle,
    Avatar,
    FeedsItem,
    HasInviteList,
    Button,
    Dialog,
    PostLoading,
    IconLabel,
    Poptip,
    ScrollView,
    PostError,
    PostWelfare,
    PostAccuse,
    ShareDownload,
    NoticeBar,
    PrestigeIcon,
    PostHeader,
    InviteAnswerList,
    Icon,
    DefaultLayout,
    GroupEntry,
    ManageDialog,
    GroupOwner,
    PostText,
  },
  mixins: [postDetail, postReportData, pageMixin],
  data() {
    return {
      postId: '',
      pageError: false,
      // postInfo: null,
      // creatorInfo: null,
      // groupInfo: null,
      answerList: [],
      sortPoptipShow: false,
      answerLoading: true,
      answerEnd: false,
      answerError: false,
      answerPageInfo: {
        pageSize: 10,
        page: 1,
      },
      // 底部按钮显示状态
      bottomBtnListStatus: false,
      // postAction: null, // 帖子行为信息
      lockFollowBtn: false,
      cancelFollowDialogStatus: false,
      inviteList: [],
      sortTypeList: SORT_TYPE_LIST,
      currentSortTypeIndex: 0,
      // 删除
      deleteDialogStatus: false,
      // 举报
      accusePopupStatus: false,
      reportPageId: reportEnum.pageid.QUESTION_DETAIL,
      reportContentType: reportEnum.contenttype.QUESTION_DETAIL,
      retryCount: 0,
      maxRetryCount: 0,
      retryTimer: null,
      pageErrorText: '系统繁忙',
      showListEmpty: false,
      postErrorStatus: 'fail',
      pageLoaded: false,
      showAnswerList: true, // 从发布器进来设置false展示邀请列表
      inviteListEmpty: false, // 列表是否为空
      postActionLoading: false,
      invitedUserList: [],
      headerStyle: {},
      isFromGroup: false, // 是否从圈子打开, http://tapd.oa.com/GamingCommunity/prong/stories/view/1020419237859949769
      showCollapsed: false, // 是否展开被折叠的回答
      collapsedAnswerList: [], // 被折叠的回答
      collapsedAnswerEnd: false,
      collapsedAnswerPageInfo: {
        pageSize: 10,
        page: 1,
      },
      collapseAnswerTipsShow: false,
      isShowManageDialog: false,
      prevId: '', // 上级来源页ID
    };
  },
  computed: {
    ...mapState({
      isApp: state => state.isApp,
      isIOS: state => state.isIOS,
      isAndroid: state => state.isAndroid,
      userInfo: state => state.user.userInfo,
      dataSource: state => state[PAGE_STORE_MODULE_NAME].dataSource,
      hasPerm: state => state.manager?.hasPerm,
    }),
    contentComputed() {
      // console.log('contentComputed', this.postInfo);
      if (this.postInfo.content) {
        // console.log('this.postInfo.content', this.postInfo.content);
        return this.postInfo.content;
      } if (this.postInfo.extent && this.postInfo.extent.preRichText) {
        return this.postInfo.extent.preRichText;
      }
      // console.log('this.postInfo.summary', this.postInfo.summary);
      return this.postInfo.summary;
    },
    currentSortType() {
      return this.sortTypeList[this.currentSortTypeIndex];
    },
    isUserEqualToAuthor() {
      const isHave = this.userInfo && this.userInfo.uid && this.postInfo;
      return isHave && (Number(this.userInfo.uid) === Number(this.postInfo.creatorId));
    },
    postError() {
      if (this.pageError) {
        return this.pageErrorText;
      }
      if (this.postInfo && this.postInfo.status === 2 && !this.isUserEqualToAuthor) {
        return '问题正在审核';
      }
      if (this.postInfo && this.postInfo.status === 4 && !this.isUserEqualToAuthor) {
        return '问题审核未通过';
      }
      if (this.postInfo && this.postInfo.status === 7 && !this.isUserEqualToAuthor) {
        return '问题被管理员下架';
      }
      if (this.postInfo && this.postInfo.status === 9) {
        return '问题已被删除';
      }
      if ([2, 4, 6, 7, 9].indexOf(Number(this.postInfo.status)) === -1) {
        return `状态异常: ${this.postInfo.status}`;
      }
      return false;
    },
    showHeader() {
      const { _gcwv: wv } = this.$route.query;
      return (this.isApp && wv && isBitTrue(Number(wv), 1));
    },
    hideAnswerList() { // 问题被下架删除等，不展示回答
      return this.postInfo && this.postInfo.status !== 6;
    },
    listEnd() { // 正常回答和折叠回答都拉完了
      if (this.showCollapsed) {
        return this.collapsedAnswerEnd;
      }
      return this.answerEnd;
    },
    unCollapsedAnswerCnt() { // 没折叠的回答数量
      const n = this.postInfo.answerCnt - this.postInfo.collapseAnswerCnt;
      return n > 0 ? n : 0;
    },
    allListLength() {
      return this.answerList.length + this.collapsedAnswerList.length;
    },
    // 当前展开的列表数量
    showListLength() {
      return this.answerList.length + (this.showCollapsed ? this.collapsedAnswerList.length : 0);
    },
  },
  watch: {
    userInfo(newVal, oldVal) {
      if (this.pageLoaded) {
        if (newVal && !oldVal) {
          // 从无到有就是登录
          this.initPageData({
            silent: true,
          });
        }
      }
    },
  },
  storeName: PAGE_STORE_MODULE_NAME,
  storeModules: {
    [PAGE_STORE_MODULE_NAME]: pageStore,
  },
  asyncData({ store, route }, preData) {
    if (!store.state[PAGE_STORE_MODULE_NAME]) {
      store.registerModule(PAGE_STORE_MODULE_NAME, pageStore);
    }
    const { questionId } = route.query;
    // console.log('call async Data', preData);
    return store.dispatch(`${PAGE_STORE_MODULE_NAME}/initPageData`, {
      params: {
        postId: questionId,
        postType: 1,
      },
      preData,
    });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler);
    clearTimeout(this.retryTimer);
    this.pageError = false;
    this.resetData();
  },
  mounted() {
    this.resetData();
    this.prevId = this.$route.query.prev_id;
    this.postId = this.$route.query.questionId;
    this.isFromGroup = this.$route.query.pageFrom === '1306';
    // 从发布器打开回答带参数 openScene=publisher
    const { openScene } = this.$route.query;
    if (openScene === 'publisher') {
      this.showAnswerList = false;
    }
    // this.pageLoading = true;
    this.scrollHandler = throttle(() => {
      if (this.$refs.questionDetailBtnList && this.$refs.answerDetailHeaderRef) {
        const b = this.$refs.questionDetailBtnList.getBoundingClientRect().bottom;
        this.bottomBtnListStatus = b < 0;
        const h = this.$refs.answerDetailHeaderRef.offsetHeight;
        const scrollTarget = this.getScrollEventTarget(this.$el);
        const { scrollTop, scrollY } = scrollTarget;
        const t = scrollTop || scrollY || 0;
        const startRate = 0.4; // 从标题隐藏 40% 的时候开始变化
        let rate = 0;
        if (t >= h) {
          rate = 1;
        } else if (t <= h * startRate) {
          rate = 0;
        } else {
          rate = Math.round(((t - h * startRate) / (h - h * startRate)) * 100) / 100;
        }
        this.headerStyle = {
          transform: `translateY(${`${40 + rate * -40}%`})`,
          opacity: rate,
          pointerEvents: rate > 0.1 ? 'auto' : 'none',
        };
      }
    }, 17);
    if (this.postId) {
      if (this.isApp) {
        this.registerEvent();
        this.$store.dispatch('user/getUserInfo')
          .then(() => {
            if (this.userInfo) {
              this.initPageData();
              if (openScene === 'publisher') {
                this.getPostAction();
              }
            } else {
              this.initPageDataNotLogin();
            }
          })
          .catch((err) => {
            logger.log('getUserInfo', err);
          });
      } else {
        this.reportPageId = reportEnum.pageid.QUESTION_DETAIL_H5;
        this.initPageDataH5();
      }
    } else {
      this.pageError = true;
      aegis.report('详情页打开异常');
    }
  },
  methods: {
    resetData() {
      this.postId = '';
      this.pageError = false;
      this.answerList = [];
      this.sortPoptipShow = false;
      this.answerLoading = true;
      this.answerEnd = false;
      this.answerError = false;
      this.answerPageInfo = {
        pageSize: 10,
        page: 1,
      };
      this.bottomBtnListStatus = false;
      // postAction: null, // 帖子行为信息
      this.lockFollowBtn = false;
      this.cancelFollowDialogStatus = false;
      this.inviteList = [];
      this.sortTypeList = SORT_TYPE_LIST;
      this.currentSortTypeIndex = 0;
      // 删除
      this.deleteDialogStatus = false;
      // 举报
      this.accusePopupStatus = false;
      this.retryCount = 0;
      this.maxRetryCount = 0;
      this.retryTimer = null;
      this.pageErrorText = '系统繁忙';
      this.showListEmpty = false;
      this.postErrorStatus = 'fail';
      this.pageLoaded = false;
      this.showAnswerList = true; // 从发布器进来设置false展示邀请列表
      this.inviteListEmpty = false; // 列表是否为空
    },
    registerEvent() {
      receiveGlobalNotification('g_updatePost', (params) => {
        let findIndex; let findItem; let isCollapsed;
        for (let i = 0; i < this.answerList.length; i++) {
          if (this.answerList[i].postInfo && Number(this.answerList[i].postInfo.id) === Number(params.id)) {
            findIndex = i;
            findItem = this.answerList[i];
            break;
          }
        }
        if (!findItem) {
          for (let i = 0; i < this.collapsedAnswerList.length; i++) {
            const  isEqual = Number(this.collapsedAnswerList[i].postInfo.id) === Number(params.id);
            if (this.collapsedAnswerList[i].postInfo && isEqual) {
              findIndex = i;
              findItem = this.collapsedAnswerList[i];
              isCollapsed = true;
              break;
            }
          }
        }
        if (findItem) {
          logger.info('receive GlobalNotification success', params);
          // console.warn('params.operate', params.operate);
          switch (params.operate) {
            case 'delete':
              if (isCollapsed) { // 被折叠
                this.collapsedAnswerList = [
                  ...this.collapsedAnswerList.slice(0, findIndex),
                  ...this.collapsedAnswerList.slice(findIndex + 1),
                ];
                this.postInfo.collapseAnswerCnt -= 1;
              } else {
                this.answerList = [
                  ...this.answerList.slice(0, findIndex),
                  ...this.answerList.slice(findIndex + 1),
                ];
              }
              this.postInfo.answerCnt -= 1;
              logger.info('接收删除回答成功', params.id);
              break;
            case 'addAnswer':
              this.handlerAddAnswer(params.data);
              break;
            case 'postAction':
              switch (params.data) {
                case 'like':
                  findItem.postInfo.extent.userActionWithPost.ifLike = true;
                  findItem.postInfo.likeCnt += 1;
                  break;
                case 'unlike':
                  findItem.postInfo.extent.userActionWithPost.ifLike = false;
                  findItem.postInfo.likeCnt -= 1;
                  break;
                case 'dislike':
                  // console.warn('dislike');
                  findItem.postInfo.extent.userActionWithPost.ifLike = false;
                  findItem.postInfo.extent.userActionWithPost.ifDislike = true;
                  findItem.postInfo.negativeCnt += 1;
                  logger.warn('dislike', findItem);
                  break;
                case 'undislike':
                  findItem.postInfo.extent.userActionWithPost.ifLike = false;
                  findItem.postInfo.extent.userActionWithPost.ifDislike = false;
                  logger.warn('undislike', findItem);
                  findItem.postInfo.negativeCnt -= 1;
                  break;
                case 'happy':
                  logger.warn('happy');
                  findItem.postInfo.extent.userActionWithPost.ifLike = false;
                  findItem.postInfo.extent.userActionWithPost.ifHappy = true;
                  findItem.postInfo.happyCnt += 1;
                  break;
                case 'unhappy':
                  logger.warn('unhappy');
                  findItem.postInfo.extent.userActionWithPost.ifLike = false;
                  findItem.postInfo.extent.userActionWithPost.ifHappy = false;
                  findItem.postInfo.happyCnt -= 1;
                  break;
                default:
                  //
              }
              break;
            case 'commentCnt':
              findItem.postInfo.commentCnt = Number(params.data);
              break;
            case 'editAnswer':
              // eslint-disable-next-line no-case-declarations
              const postInfo = params.data;
              if (!postInfo) return;
              findItem.postInfo.summary = postInfo.summary;
              // 修改回答后可能会从折叠变为非折叠
              // eslint-disable-next-line no-case-declarations
              const flag = parseInt(postInfo.flag, 10);
              // eslint-disable-next-line no-case-declarations, no-bitwise
              const isCurrentCollapsed = (flag & 65536) === 65536;
              if (!isCollapsed && isCurrentCollapsed) { // 原来非折叠，现在被折叠
                this.answerList = [
                  ...this.answerList.slice(0, findIndex),
                  ...this.answerList.slice(findIndex + 1),
                ];
                this.collapsedAnswerList = [
                  findItem,
                  ...this.collapsedAnswerList,
                ];
                this.postInfo.collapseAnswerCnt += 1;
              } else if (isCollapsed && !isCurrentCollapsed) { // 原来折叠，现在非折叠
                this.collapsedAnswerList = [
                  ...this.collapsedAnswerList.slice(0, findIndex),
                  ...this.collapsedAnswerList.slice(findIndex + 1),
                ];
                this.answerList = [
                  findItem,
                  ...this.answerList,
                ];
                this.postInfo.collapseAnswerCnt -= 1;
              }
              break;
            default:
          }
        }
        // 删除自己的回答
        if (params.operate === 'delete' && Number(this.postAction.answerId) === Number(params.id)) {
          this.postAction.ifAnswer = false;
          this.postAction.answerId = '';
        }
      });
    },
    initPageData({ silent = false } = {}) {
      Promise.all([this.getQuestionInfo({ silent })])
        .then(([res1]) => {
          this.pageLoaded = true;
          if (res1 !== 'error') {
            this.pageLoading = false;
            this.readPostCallback({ postInfo: this.postInfo });
            this.syncPostInfo({ postInfo: this.postInfo });
            this.reportData({
              moduleid: 1,
              eventtype: 1,
              extendid: 1,
              prev_id: this.prevId,
            }, '问题详情页曝光');
            this.reportData({
              operid: '1501000010101',
            }, '所有内容详情页页面曝光');
            this.setRightButton();
            this.$nextTick(() => {
              this.bindScrollHandler();
            });
            this.pageError = false;
          } else if (this.retryCount < this.maxRetryCount) {
            this.retryCount += 1;
            logger.info(`retry initPageData: count ${this.retryCount}`);
            clearTimeout(this.retryTimer);
            this.retryTimer = setTimeout(() => {
              this.initPageData({ silent: true });
            }, 500 * (this.retryCount));
          } else {
            this.pageLoading = false;
            if (!silent) {
              this.pageError = true;
              aegis.report('详情页打开异常');
            }
          }
          return true;
        });
      this.getAnswerList();
      this.getQuestionInvitedUser();
    },
    initPageDataNotLogin() {
      Promise.all([this.getQuestionInfo()])
        .then(([res1]) => {
          this.pageLoaded = true;
          if (res1 !== 'error') {
            this.readPostCallback({ postInfo: this.postInfo });
            this.syncPostInfo({ postInfo: this.postInfo });
            this.reportData({
              moduleid: 1,
              eventtype: 1,
              extendid: 1,
              prev_id: this.prevId,
            }, '问题详情页曝光');
            this.reportData({
              operid: '1501000010101',
            }, '所有内容详情页页面曝光');
            this.setRightButton();
            this.$nextTick(() => {
              this.bindScrollHandler();
            });
            this.pageError = false;
          } else if (this.retryCount < this.maxRetryCount) {
            this.retryCount += 1;
            logger.info(`retry initPageData: count ${this.retryCount}`);
            clearTimeout(this.retryTimer);
            this.retryTimer = setTimeout(() => {
              this.initPageDataNotLogin();
            }, 500 * (this.retryCount));
          } else {
            this.pageLoading = false;
            this.pageError = true;
            aegis.report('详情页打开异常');
          }
          return true;
        });
      this.getAnswerList();
      this.getQuestionInvitedUser();
    },
    initPageDataH5() {
      Promise.all([this.getQuestionInfo({
        params: {
          flag: 2,
        },
      })])
        .then(([res1]) => {
          this.pageLoaded = true;
          if (res1 !== 'error') {
            this.reportData({
              moduleid: 1,
              eventtype: 1,
              extendid: 1,
              prev_id: this.prevId,
            }, '问题详情页曝光');
            this.reportData({
              pageid: '2000',
              moduleid: 1,
              eventtype: 1,
              extendid: 1,
            }, '所有内容详情页页面曝光');
            setH5Share({
              shareTitle: this.postInfo.title || '闪现一下',
              shareDesc: unicodeString.slice(this.postInfo.summary, 200) || '来自闪现一下，来闪现，从不落单~',
              shareLink: getShareUrl(),
              shareImgUrl: this.getPostCoverUrl(this.postInfo) || 'https://static.gameplus.qq.com/business/Share/iOSAPPicon100.png',
            });
            this.$nextTick(() => {
              this.bindScrollHandler();
            });
            this.pageError = false;
          } else if (this.retryCount < this.maxRetryCount) {
            this.retryCount += 1;
            logger.info(`retry initPageData: count ${this.retryCount}`);
            clearTimeout(this.retryTimer);
            this.retryTimer = setTimeout(() => {
              this.initPageDataH5();
            }, 500 * (this.retryCount));
          } else {
            this.pageLoading = false;
            this.pageError = true;
            aegis.report('详情页打开异常');
          }
          return true;
        });
      this.getAnswerList();
      this.getQuestionInvitedUser();
    },
    getScrollEventTarget(element) {
      let currentNode = element;
      // bugfix, see http://w3help.org/zh-cn/causes/SD9013 and http://stackoverflow.com/questions/17016740/onscroll-function-is-not-working-for-chrome
      while (currentNode && currentNode.tagName !== 'HTML' && currentNode.tagName !== 'BODY' && currentNode.nodeType === 1) {
        const { overflowY } = getComputedStyle(currentNode);
        if (overflowY === 'scroll' || overflowY === 'auto') {
          return currentNode;
        }
        currentNode = currentNode.parentNode;
      }
      return window;
    },
    bindScrollHandler() {
      const scrollTarget = this.getScrollEventTarget(this.$el);
      scrollTarget.addEventListener('scroll', this.scrollHandler);
    },
    getQuestionInfo({ retry = false, params = {} } = {}) {
      const { questionId, openScene } = this.$route.query;
      if (openScene === 'publisher' && !retry) {
        // return Promise.resolve(true);
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(this.getQuestionInfo({ retry: true }));
          }, 3000);
        });
      }
      // eslint-disable-next-line no-unreachable
      return new Promise((resolve) => {
        this.$store.dispatch(`${PAGE_STORE_MODULE_NAME}/initPageData`, {
          params: {
            postId: questionId,
            userId: this.userInfo ? this.userInfo.uid : 0,
            ...params,
          },
        }).then((res) => {
          if (res.info && res.info[0] && res.info[0].postInfo) {
            return resolve(res);
          }
          logger.error('getQuestionInfo error', res);
          // this.pageErrorText = '系统繁忙(null)';
          return resolve('error');
        }, (err) => {
          logger.error('getQuestionInfo error', err);
          this.postErrorStatus = 'fail';
          if (err.status === 200 && err.data) {
            this.pageErrorText = '系统繁忙';
          } else if (err.response) {
            this.pageErrorText = '系统繁忙';
          } else if (err.request) {
            this.pageErrorText = '网络竟然崩溃了，换个姿势刷新试试';
            this.postErrorStatus = 'network';
          } else {
            this.pageErrorText = '系统繁忙';
          }
          return resolve('error');
        });
      });
    },
    getAnswerList(refresh = false) {
      this.answerLoading = true;
      answerApi.getAnswerListByQuestionId({
        questionId: this.postId,
        userId: this.userInfo && this.userInfo.uid,
        pageSize: this.answerPageInfo.pageSize,
        pageNum: this.answerPageInfo.page,
        sortType: this.currentSortType.value,
      })
        .then((res) => {
          if (this.$store.state.app.isInBackground) return Promise.stop();
          this.answerLoading = false;
          if (this.answerList.length === 0 || refresh) {
            this.answerList = res.info;
          } else {
            // const newAnswerList = res.info.filter(item =>
            // !this.answerList.some(answer => answer.postInfo.id === item.postInfo.id));
            // this.answerList = this.answerList.concat(newAnswerList);
            this.answerList = unionList(this.answerList, res.info, { key: 'postInfo.id' });
          }
          this.answerEnd = !!res.ifLast;
          if (res.pageInfo) {
            // 后端返回的page是+1后的，total_number和total_page是不准确的
            this.answerPageInfo.page = res.pageInfo.page;
          } else {
            this.answerPageInfo.page += 1;
          }
          if (this.postInfo.collapseAnswerCnt > 0) {
            this.reportData({
              moduleid: 78,
              eventtype: 2,
              extendid: 1,
            }, '问题详情页回答折叠栏曝光');
          }
          return true;
        })
        .catch((err) => {
          if (this.$store.state.app.isInBackground) return Promise.stop();
          this.answerLoading = false;
          this.answerError = true;
          if (refresh) {
            this.answerList = [];
          }
          logger.log('getAnswerListByQuestionId err', err);
          return false;
        });
    },
    getCollapseAnswerList(refresh = false) {
      this.answerLoading = true;
      return answerApi.getAnswerListByQuestionId({
        questionId: this.postId,
        userId: this.userInfo && this.userInfo.uid,
        pageSize: this.collapsedAnswerPageInfo.pageSize,
        pageNum: this.collapsedAnswerPageInfo.page,
        sortType: this.currentSortType.value,
        flag: 1, // 拉取折叠回答
      })
        .then((res) => {
          if (this.$store.state.app.isInBackground) return Promise.stop();
          this.answerLoading = false;
          if (this.collapsedAnswerList.length === 0 || refresh) {
            this.collapsedAnswerList = res.info;
            // console.log('collapsedAnswerList', res.info);
          } else {
            // console.log('collapsedAnswerList concat', this.collapsedAnswerList, res.info);
            this.collapsedAnswerList = unionList(this.collapsedAnswerList, res.info, { key: 'postInfo.id' });
          }
          this.collapsedAnswerEnd = !!res.ifLast;
          if (res.pageInfo) {
            // 后端返回的page是+1后的，total_number和total_page是不准确的
            this.collapsedAnswerPageInfo.page = res.pageInfo.page;
          } else {
            this.collapsedAnswerPageInfo.page += 1;
          }
          return true;
        })
        .catch((err) => {
          if (this.$store.state.app.isInBackground) return Promise.stop();
          this.answerLoading = false;
          this.answerError = true;
          if (refresh) {
            this.collapsedAnswerList = [];
          }
          logger.log('getAnswerListByQuestionId err', err);
          return false;
        });
    },
    onReachBottomHandler() {
      if (this.answerLoading || this.answerError || this.listEnd) return;
      this.reportData({
        moduleid: 1,
        eventtype: 8,
        extendid: 1,
      }, '问题详情页上拉加载');
      if (this.showCollapsed) {
        this.getCollapseAnswerList();
      } else {
        this.getAnswerList();
      }
    },
    onBottomErrorClickHandler() {
      this.answerError = false;
      this.getAnswerList();
    },
    onAnswerItemClickHandler(answerInfo, index, toComment) {
      this.reportData({
        moduleid: 3,
        eventtype: 3,
        extendid: 1,
        indexid: index + 1,
      }, '问题详情页进入回答详情页');
      if (this.isApp) {
        let targetUrl = `${window.location.origin}/community/answer-detail/?answerId=${answerInfo.postInfo.id}&questionId=${this.postId}&pageFrom=${reportEnum.pageid.QUESTION_DETAIL}&_gcwv=2`;
        if (toComment) {
          targetUrl += '&anchor=comment';
        }
        // console.log('window.jsbridge.biz', targetUrl, answerInfo);
        openWithWebbundle(targetUrl, {
          ...answerInfo,
          proto: 'HomePagePost',
        });
      } else {
        this.$router.push({
          name: 'community-answer-detail',
          query: {
            answerId: answerInfo.postInfo.id,
            questionId: this.postId,
            pageFrom: reportEnum.pageid.QUESTION_DETAIL,
            _gcwv: 2,
          },
        });
      }
    },
    onInviteClickHandlerReport() {
      this.reportData({
        moduleid: 20,
        eventtype: 3,
        extendid: 1,
      }, '问题详情页邀请回答点击');
    },
    onInviteListClickHandlerReport() {
      this.reportData({
        moduleid: 20,
        eventtype: 3,
        extendid: 3,
      }, '问题详情页邀请回答更多点击');
    },
    onInviteClickHandler() {
      if (this.isApp) {
        const url = `${window.location.origin}/community/answer-invite/?questionId=${this.postId}&pageFrom=${reportEnum.pageid.QUESTION_DETAIL}&_gcwv=2`;
        uniOpenUrl(url);
      } else {
        this.$router.push({
          name: 'community-answer-invite',
          pageFrom: reportEnum.pageid.QUESTION_DETAIL,
          _gcwv: 2,
        });
      }
    },
    onSortClickHandler(index) {
      this.currentSortTypeIndex = index;
      this.answerLoading = true;
      this.answerEnd = false;
      this.answerError = false;
      this.answerPageInfo.page = 1;
      // this.answerList = [];
      this.getAnswerList(true);
      this.sortPoptipShow = false;
      if (this.currentSortType.value === 4) {
        this.reportData({
          moduleid: 37,
          eventtype: 3,
          extendid: 3,
        }, '问题详情页最热回答点击');
      } else if (this.currentSortType.value === 2) {
        this.reportData({
          moduleid: 37,
          eventtype: 3,
          extendid: 3,
        }, '问题详情页最新回答点击');
      }
    },
    onFollowClickHandlerReport() {
      this.reportData({
        moduleid: 7,
        eventtype: 3,
        extendid: 1,
      }, '问题详情页加关注按钮点击');
    },
    onCancelFollowClickHandlerReport() {
      this.reportData({
        moduleid: 7,
        eventtype: 3,
        extendid: 2,
      }, '问题详情页取消关注按钮点击');
    },
    onFollowClickHandler() {
      if (!this.postAction || Object.keys(this.postAction).length === 0) {
        this.$emit('onGetPostAction');
        this.getPostAction();
        this.$toast('系统繁忙，请稍候再试');
        return false;
      }
      if (this.lockFollowBtn) return false;
      if (this.postAction && this.postAction.ifFollow) {
        this.cancelFollowDialogStatus = true;
      } else {
        this.postFollowAction('follow')
          .then(() => {
            this.$toast('关注成功');
            this.reportData({
              moduleid: 7,
              eventtype: 6,
              extendid: 1,
            }, '问题详情页关注成功');
            this.postAction.ifFollow = true;
            this.sendNotice({
              operate: 'postAction',
              data: 'follow',
            });
          })
          .catch(() => {
            this.$toast('关注失败');
          })
          .finally(() => {
            this.lockFollowBtn = false;
          });
      }
      return true;
    },
    onCancelFollowHandler() {
      this.lockFollowBtn = true;
      this.postFollowAction('unfollow')
        .then(() => {
          this.postAction.ifFollow = false;
          this.sendNotice({
            operate: 'postAction',
            data: 'unfollow',
          });
          this.$toast('取消关注成功');
          this.reportData({
            moduleid: 7,
            eventtype: 6,
            extendid: 2,
          }, '问题详情页取消关注成功');
        })
        .catch(() => {
          this.$toast('取消关注失败');
        })
        .finally(() => {
          this.lockFollowBtn = false;
        });
    },
    postFollowAction(action) {
      return postApi.postAction({
        postId: this.postInfo.id,
        postType: this.postInfo.type,
        postCreatorId: this.creatorInfo.uid,
        userId: this.userInfo.uid,
        groupId: this.postInfo.groupId,
        action,
      });
    },
    getPostAction() {
      if (!this.userInfo || !this.userInfo.uid) {
        this.$toast('请先登录');
        return Promise.resolve('error');
      }
      return postApi.getPostAction({
        userId: this.userInfo && this.userInfo.uid,
        postId: this.postId,
      }).then(res => Promise.resolve(res), () => Promise.resolve('error'));
    },
    onGetPostAction() {
      this.getPostAction()
        .then((res2) => {
          if (res2 !== 'error') {
            this.postAction = res2;
          }
        });
    },
    onAnswerClickHandlerReport() {
      this.reportData({
        moduleid: 21,
        eventtype: 3,
        extendid: 1,
      }, '问题详情页写回答点击');
    },
    onAnswerClickHandler() {
      postAnswer({
        postId: this.postId,
        groupId: this.postInfo.groupId,
        title: this.postInfo.title,
      }).then((res) => {
        // console.log('postAnswer res', res);
        this.reportData({
          moduleid: 1,
          eventtype: 6,
          extendid: 2,
        }, '回答详情页写回答成功');
        const answerInfo = {
          creator: this.userInfo,
          postInfo: {
            ...res,
            id: parseInt(res.id, 10),
          },
        };
        this.handlerAddAnswer(answerInfo);
        const localDataKey = `Q_ANSWER_INFO_${answerInfo.postInfo.id}`;
        localStorage.setItem(localDataKey, JSON.stringify(answerInfo));
        if (this.isApp) {
          const targetUrl = `${window.location.origin}/community/answer-detail/?answerId=${answerInfo.postInfo.id}&questionId=${this.postId}&pageFrom=${reportEnum.pageid.QUESTION_DETAIL}&localDataKey=${localDataKey}&_gcwv=2`;
          uniOpenUrl(targetUrl);
        } else {
          this.$router.push({
            name: 'community-answer-detail',
            query: {
              answerId: answerInfo.postInfo.id,
              questionId: this.postId,
              pageFrom: reportEnum.pageid.QUESTION_DETAIL,
              localDataKey,
              _gcwv: 2,
            },
          });
        }
      })
        .catch((err) => {
          logger.err('onAnswerClickHandler', err);
        });
    },
    onReviewAnswerClickHandler() {
      if (!this.postAction.answerId) return;
      if (this.isApp) {
        const targetUrl = `${window.location.origin}/community/answer-detail/?answerId=${this.postAction.answerId}&questionId=${this.postId}&pageFrom=${reportEnum.pageid.QUESTION_DETAIL}&_gcwv=2`;
        uniOpenUrl(targetUrl);
      } else {
        this.$router.push({
          name: 'community-answer-detail',
          query: {
            answerId: this.postAction.answerId,
            questionId: this.postId,
            pageFrom: reportEnum.pageid.QUESTION_DETAIL,
            _gcwv: 2,
          },
        });
      }
    },
    setRightButton() {
      if (this.postInfo.status === 9) return false;
      setRightButton({
        callback: () => {
          this.setShareDialog();
        },
      });
      return true;
    },
    onManageUpdate() {
      this.getQuestionInfo({
        params: {
          flag: 2,
        },
      });
    },
    setShareDialog() {
      // if (this.$store.state.isAndroid) {
      //   if (!this.userInfo) {
      //     this.$checkAuthDeco(() => {
      //       this.onGetPostAction();
      //     });
      //     return false;
      //   }
      //   if ((!this.postAction || Object.keys(this.postAction).length === 0)) {
      //     this.onGetPostAction();
      //     this.$toast('系统繁忙，请稍候再试');
      //     return false;
      //   }
      // }
      // 右上角按钮点击上报
      this.reportData({
        pageid: '1501',
        moduleid: 1,
        eventtype: 3,
        extendid: 1,
      }, '问题详情页点击右上角按钮');
      let action = [
        {
          type: 'follow',
          name: this.postAction && this.postAction.ifFollow ? '已关注' : '关注',
          native: 0,
        },
      ];
      if (this.userInfo && Number(this.userInfo.uid) === Number(this.creatorInfo.uid)) {
        action.push({
          type: 'delete',
          name: '删除',
          native: 0,
        });
      } else {
        action.push({
          type: 'accuse',
          name: '举报',
          native: 0,
        });
        action.push({
          type: 'block',
          name: '屏蔽',
          native: 0,
        });
      }
      action.push({
        type: 'copyLink',
        name: '复制链接',
        native: 1,
      });

      let showShareDialogFunc = showShareDialog;
      if (this.$compareVersion('1.8.2') >= 0) {
        showShareDialogFunc = showShareDialogV2;
        action = [{
          type: 'tgFriend',
        }].concat(action);
        if (this.hasPerm) { // todo 管理员判断
          action.push({
            type: 'custom',
            title: '管理内容',
            icon: decodeURIComponent('https://static.gameplus.qq.com/business/iconh5/manage/icon_94_manege.png'),
            id: 1,
            native: 0,
          });
          this.reportData({
            operid: '1501000830201',
          }, '内容详情页操作面板管理按钮曝光');
        }
      }
      const shareContent = {
        shareVisible: this.postInfo.status === 6 ? 1 : 0, // 0 分享不可见， 1 可见
        title: this.postInfo.title || '闪现一下',
        summary: unicodeString.slice(this.postInfo.summary, 200) || '来自闪现一下，来闪现，从不落单~',
        targetUrl: getShareUrl(),
        thumbUrl: this.getPostCoverUrl(this.postInfo) || 'https://static.gameplus.qq.com/business/Share/iOSAPPicon100.png',
        action,
        postInfo: {
          postId: this.postId,
          postType: this.postType,
          postCreatorId: (this.creatorInfo && this.creatorInfo.uid),
          groupId: this.postInfo.groupId,
          action: this.postAction && this.postAction.ifFollow ? 'follow' : 'unfollow',
          userId: (this.userInfo && this.userInfo.uid) || 0,
        },
      };
      logger.info('showShareDialog', shareContent);
      showShareDialogFunc(shareContent)
        .then((res2) => {
          // console.log(`showShareDialog: ${JSON.stringify(res2)}`);
          if (res2.result === 0) {
            switch (res2.data.type) {
              case 'follow':
                this.$checkAuthDeco(() => {
                  this.onFollowClickHandler();
                });
                break;
              case 'delete':
                this.$checkAuthDeco(() => {
                  this.deleteDialogStatus = true;
                });
                break;
              case 'accuse':
                if (checkEnv().isSwitchOn) {
                  this.accusePopupStatus = true;
                } else {
                  this.$checkAuthDeco(() => {
                    this.accusePopupStatus = true;
                  });
                }
                break;
              case 'block':
                postApi.postAction({
                  postId: this.postInfo.id,
                  postType: this.postInfo.type,
                  postCreatorId: this.postInfo.creatorId,
                  userId: (this.userInfo && this.userInfo.uid) || 0,
                  groupId: this.postInfo.groupId,
                  action: 'block',
                }).then(() => {
                  this.$toast('将为你减少此内容推荐');
                })
                  .catch((err) => {
                    logger.err('postAction', err);
                    this.$toast('将为你减少此内容推荐');
                  });
                break;
              case 'WXSession':
                this.sendNotice({
                  operate: 'share',
                  data: 'WXSession',
                });
                break;
              case 'WXTimeline':
                this.sendNotice({
                  operate: 'share',
                  data: 'WXTimeline',
                });
                break;
              case 'QQFriend':
                this.sendNotice({
                  operate: 'share',
                  data: 'QQFriend',
                });
                break;
              case 'QQZone':
                this.sendNotice({
                  operate: 'share',
                  data: 'QQZone',
                });
                break;
              case 'custom':
                if (parseInt(res2.data.id, 10) === 1) {
                  this.isShowManageDialog = true;
                }
                break;
              default:
                break;
            }
          }
          // 处理上报
          if (res2.data.type) {
            switch (res2.data.type) {
              case 'follow':
                break;
              case 'delete':
                this.reportData({
                  pageid: '1501',
                  moduleid: 32,
                  eventtype: 3,
                  extendid: 1,
                }, '帖子详情页点击删除按钮');
                break;
              case 'accuse':
                this.reportData({
                  pageid: '1501',
                  moduleid: 12,
                  eventtype: 3,
                  extendid: 1,
                }, '帖子详情页点击举报');
                break;
              case 'WXSession':
                this.reportData({
                  pageid: '1501',
                  moduleid: 25,
                  eventtype: 3,
                  extendid: 2,
                }, '分享到微信点击');
                break;
              case 'WXTimeline':
                this.reportData({
                  pageid: '1501',
                  moduleid: 25,
                  eventtype: 3,
                  extendid: 3,
                }, '分享到微信好友点击');
                break;

              case 'QQFriend':
                this.reportData({
                  pageid: '1501',
                  moduleid: 25,
                  eventtype: 3,
                  extendid: 4,
                }, '分享到QQ好友点击');
                break;
              case 'QQZone':
                this.reportData({
                  pageid: '1501',
                  moduleid: 25,
                  eventtype: 3,
                  extendid: 5,
                }, '分享到QQ空间点击');
                break;
              case 'custom':
                if (parseInt(res2.data.id, 10) === 1) {
                  this.reportData({
                    operid: '1501000830301',
                  }, '内容详情页操作面板管理按钮点击');
                }
                break;
              default:
                break;
            }
          }
        });
      return true;
    },
    // 获取帖子的封面图片
    getPostCoverUrl(postInfo) {
      return (postInfo && postInfo.pics && postInfo.pics.info && postInfo.pics.info[0] && postInfo.pics.info[0].url) || '';
    },
    // 删除帖子
    onDeleteDialogConfirmHandler() {
      if (this.deletePostLoading) return false;
      this.deletePostLoading = true;
      postApi.deletePost({
        id: this.postId,
      }).then(() => {
        this.$toast('删除成功');
        this.sendNotice({
          operate: 'delete',
          data: '',
        });
        popBack();
      })
        .catch(() => {
          this.$toast('删除失败');
        })
        .finally(() => {
          this.deletePostLoading = false;
        });
      return true;
    },
    // 通知其他页面
    sendNotice({
      operate, // 操作，delete postComment postAnswer postAction
      data = '',
    }) {
      if (compareVersion('1.3') >= 0) {
        // 1.3版本支持全局通信
        sendGlobalNotification('g_updatePost', {
          operate,
          id: this.postId,
          data,
        });
      } else {
        // 通知hippy页面
        noticeSend({
          noticeId: 'HippyNotification',
          noticeEvent: 'webUpdatePost',
          noticeParams: {
            operate,
            id: this.postId,
            data,
          },
        }).then(() => {
          // console.log('发送hippy通知成功', res);
        });
      }
    },
    onAvatarClickHandler() {
      this.$linkToPersonal(this.creatorInfo.uid);
    },
    onAvatarClickReport() {
      this.reportData({
        moduleid: 4,
        eventtype: 3,
        extendid: 1,
      }, '问题详情页作者头像点击');
    },
    onGroupClickHandler() {
      this.$linkToGroup(this.groupInfo.id);
    },
    onGroupClickReport() {
      this.reportData({
        moduleid: 33,
        eventtype: 3,
        extendid: 1,
      }, '问题详情页所属圈子点击');
    },
    onSortBtnClickHandler() {
      this.reportData({
        moduleid: 37,
        eventtype: 3,
        extendid: 1,
      }, '问题详情页排序按钮点击');
    },
    onHeaderMenuClickHandler() {
      this.setShareDialog();
    },
    onTopicClickHandler(topicId) {
      openView({
        options: `tgc://native?moduleName=topic&topicId=${topicId}`,
      });
    },
    onTopicLabelLayout() {
      this.reportData({
        pageid: 1501,
        moduleid: 52,
        eventtype: 2,
        extendid: 1,
      }, '所有内容详情页话题入口曝光');
      this.reportData({
        moduleid: 52,
        eventtype: 2,
        extendid: 1,
      }, '图文详情页话题入口曝光');
    },
    onTopicClickHandlerReport() {
      this.reportData({
        pageid: 1501,
        moduleid: 52,
        eventtype: 3,
        extendid: 2,
      }, '所有内容详情页话题入口点击');
      this.reportData({
        moduleid: 52,
        eventtype: 3,
        extendid: 2,
      }, '图文详情页话题入口点击');
    },
    // 邀请回答列表完成，接口返回成功后触发
    hasInitInviteAnswerList(inviteList) {
      if (inviteList.length === 0) {
        this.inviteListEmpty = true;
      } else {
        this.inviteListEmpty = false;
        this.reportData({
          moduleid: 20,
          eventtype: 2,
          extendid: 2,
        }, '问题详情页邀请回答更多曝光');
      }
    },
    // 接收回答列表的通信
    registerAnswerPostActionNotice() {
      receiveGlobalNotification('g_updatePost', (params) => {
        logger.info('receive GlobalNotification success', params);
        let findIndex; let findItem;
        for (let i = 0; i < this.answerList.length; i++) {
          if (this.answerList[i].postInfo && Number(this.answerList[i].postInfo.id) === Number(params.id)) {
            findIndex = i;
            findItem = this.answerList[i];
            break;
          }
        }
        if (findItem) {
          switch (params.operate) {
            case 'delete':
              this.answerList = [
                ...this.answerList.slice(0, findIndex),
                ...this.answerList.slice(findIndex + 1),
              ];
              this.postInfo.answerCnt -= 1;
              logger.info('接收删除回答成功', params.id);
              break;
            default:
              //
          }
        }
        // 删除自己的回答
        if (params.operate === 'delete' && Number(this.postAction.answerId) === Number(params.id)) {
          this.postAction.ifAnswer = false;
          this.postAction.answerId = '';
        }
      });
    },
    onItemPostAction(action, data) {
      if (this.postActionLoading) return false;
      const { postInfo } = data;
      this.postActionLoading = true;
      const postAction = postInfo.extent.userActionWithPost || {};
      logger.info('onItemPostAction', action, postAction);
      postApi.postAction({
        postId: Number(postInfo.id),
        postType: postInfo.type,
        postCreatorId: postInfo.creatorId,
        userId: (this.userInfo && this.userInfo.uid) || 0,
        groupId: postInfo.groupId,
        action,
      }).then(() => {
        this.sendNotice({
          operate: 'postAction',
          data: action,
        });
        switch (action) {
          case 'like':
            postAction.ifLike = true;
            postAction.ifDislike = false;
            postAction.ifHappy = false;
            postInfo.likeCnt += 1;
            this.sendNotice({
              operate: 'likeCnt',
              data: postInfo.likeCnt,
            });
            break;
          case 'unlike':
            postAction.ifLike = false;
            postAction.ifDislike = false;
            postAction.ifHappy = false;
            postInfo.likeCnt = postInfo.likeCnt > 0 ? postInfo.likeCnt - 1 : 0;
            this.sendNotice({
              operate: 'likeCnt',
              data: postInfo.likeCnt,
            });
            break;
          case 'dislike':
            postAction.ifDislike = true;
            postAction.ifLike = false;
            postAction.ifHappy = false;
            postInfo.negativeCnt += 1;
            this.sendNotice({
              operate: 'negativeCnt',
              data: postInfo.likeCnt,
            });
            break;
          case 'undislike':
            postAction.ifDislike = false;
            postAction.ifLike = false;
            postAction.ifHappy = false;
            postInfo.negativeCnt = postInfo.negativeCnt > 0 ? postInfo.negativeCnt - 1 : 0;
            this.sendNotice({
              operate: 'negativeCnt',
              data: postInfo.likeCnt,
            });
            break;
          case 'follow':
            postAction.ifFollow = true;
            postInfo.followCnt += 1;
            this.sendNotice({
              operate: 'followCnt',
              data: postInfo.likeCnt,
            });
            break;
          case 'unfollow':
            postAction.ifFollow = false;
            postInfo.followCnt = postInfo.followCnt > 0 ? postInfo.followCnt - 1 : 0;
            this.sendNotice({
              operate: 'followCnt',
              data: postInfo.likeCnt,
            });
            break;
          case 'happy':
            postAction.ifHappy = true;
            postAction.ifLike = false;
            postAction.ifDisike = false;
            postInfo.happyCnt += 1;
            this.sendNotice({
              operate: 'happyCnt',
              data: postInfo.happyCnt,
            });
            break;
          case 'unhappy':
            postAction.ifHappy = false;
            postAction.ifLike = false;
            postAction.ifDisike = false;
            postInfo.happyCnt = postInfo.happyCnt > 0 ? postInfo.happyCnt - 1 : 0;
            this.sendNotice({
              operate: 'happyCnt',
              data: postInfo.happyCnt,
            });
            break;
          default:
        }
        postInfo.extent.userActionWithPost = postAction;
      }, (err) => {
        if (err.data && err.data.msg) {
          this.$toast(err.data.msg);
        } else {
          this.$toast('操作失败');
        }
      })
        .finally(() => {
          this.postActionLoading = false;
        });
      return true;
    },
    // 获取邀请
    getQuestionInvitedUser() {
      getQuestionInvitedUser({
        questionId: this.postId,
        page: 1,
        pageSize: 3,
      }).then((res) => {
        // console.log('getQuestionInvitedUser', res);
        this.invitedUserList = res.invitedUserList;
      });
    },
    collapsedAnswerBarClick() {
      if (this.answerLoading) return;
      if (!this.showCollapsed) {
        this.reportData({
          moduleid: 78,
          eventtype: 3,
          extendid: 1,
        }, '问题详情页回答折叠展开按钮点击');
        this.getCollapseAnswerList().then(() => {
          this.showCollapsed = true;
        });
      } else {
        this.showCollapsed = false;
      }
    },
    // 折叠提示弹窗点击跳转
    onCollapseAnswerTipsConfirm() {
      const postId = process.env.VUE_APP_PUBLIC_ENV === 'official' ? 411299816 : 400140958;
      const {
        protocol, host,
      } = window.location;
      const postURL = `${protocol}//${host}/community/post-detail?postId=${postId}&_gcwv=2`;
      uniOpenUrl(postURL);
      this.collapseAnswerTipsShow = false;
    },
    // 点击折叠栏提示问号
    onCollapseTipsIconClickHandler() {
      this.collapseAnswerTipsShow = true;
      this.reportData({
        moduleid: 78,
        eventtype: 3,
        extendid: 2,
      }, '问题详情页回答折叠问号按钮点击');
    },
    // 添加回答后，将回答插入到列表中。区分折叠和非折叠
    handlerAddAnswer(answerInfo) {
      this.postInfo.answerCnt += 1;
      const flag = parseInt(answerInfo.postInfo.flag, 10);
      // eslint-disable-next-line no-bitwise
      if ((flag & 65536) === 65536) { // 被折叠
        this.postInfo.collapseAnswerCnt += 1;
        this.collapsedAnswerList.unshift(answerInfo);
      } else {
        this.answerList.unshift(answerInfo);
      }
      this.sendNotice({
        operate: 'updateAnswerAmount',
        data: this.postInfo.answerCnt,
      });
      this.sendNotice({
        operate: 'answerCnt',
        data: this.postInfo.answerCnt,
      });
      this.postAction.ifAnswer = true;
      this.postAction.answerId = answerInfo.postInfo.id;
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
