<template>
  <div
    v-if="userInfo"
    class="com-user-info"
  >
    <Avatar
      :avatar="userInfo.iconUrl"
      @click.native="$checkAppDeco(onAvatarClickHandler);"
      class="com-user-info__avatar"
      size="xs"
    />
    <div class="com-user-info__nickname">
      {{ userInfo.nickName }}
    </div>
    <GroupOwner
      :group-owner-type="userInfo.groupOwnerType"
      class="com-user-info__label"
    />
    <div
      v-if="userInfo.vipType===1"
      class="com-user-info__label"
    >
      <VipIcon
        :vip-type="userInfo.vipType"
        :account-type="userInfo.accountType"
        size="l"
      />
      <span class="com-user-info__desc">
        {{ userInfo.identification }}
      </span>
    </div>
    <div
      v-else
      class="com-user-info__label"
    >
      <LabelAuthorRank :level="userInfo.level" />
    </div>
    <!-- 徽章 -->
    <div class="com-user-info__label">
      <badge-icon
        v-if="userInfo.userBadge && !!userInfo.userBadge.isShow"
        :user-info="userInfo"
        :report-is-comment="true"
      />
    </div>
    <!-- 声望 -->
    <div class="com-user-info__label">
      <PrestigeIcon
        v-if="userInfo.renownInfo && userInfo.renownInfo.iconUrl"
        :icon-url="userInfo.renownInfo.iconUrl"
      />
    </div>
    <span
      v-if="ipRegion"
      class="com-user-info__ip-region"
    >{{ ipRegion }}</span>
    <div class="com-user-info__label">
      <OnlineStatus
        :status="userInfo.onlineStatus"
        :game-code="userInfo.extend && userInfo.extend.teamGameCode"
      />
    </div>
  </div>
</template>

<script>
import Avatar from '@/ui/avatar/index.vue';
import LabelAuthorRank from '@/ui/label/author-rank.vue';
import PrestigeIcon from '@/ui/label/prestige-icon.vue';
import OnlineStatus from '@/ui/label/online-status.vue';
import BadgeIcon from '@/ui/label/badge-icon.vue';
import VipIcon from '@/ui/vip-icon';
import GroupOwner from '@/ui/label/group-owner.vue';

export default {
  name: 'ComFeedsUserInfo',
  components: {
    Avatar, LabelAuthorRank, PrestigeIcon, OnlineStatus, BadgeIcon, VipIcon, GroupOwner,
  },
  props: {
    userInfo: {
      type: Object,
      required: true,
    },
    ipRegion: {
      type: String,
      default: '',
    },
  },
  data() {
    return {

    };
  },
  methods: {
    onAvatarClickHandler() {
      this.$linkToPersonal(this.commentData.userId);
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
