<template>
  <div class="com-invite-item">
    <Avatar
      :avatar="userInfo.iconUrl"
      :account-type="userInfo.accountType"
      :vip-type="userInfo.vipType"
      @click.native="$checkAppDeco($linkToPersonal, userInfo.uid);avatarClickReport();"
      size="m"
    />
    <section class="author">
      <span class="nickname">
        <span class="text ui-nowrap">{{ userInfo.nickName }}</span>
        <LabelAuthorRank
          v-show="isShowLabelAuthorRank"
          :level="userInfo.level"
          class="label"
        />
        <!-- 勋章 -->
        <badge-icon
          v-if="userInfo.userBadge && !!userInfo.userBadge.isShow"
          :user-info="userInfo"
          :report-is-invite="true"
          class="com-invite-item-main-nickname-label"
        />
        <PrestigeIcon
          v-if="userInfo.renownInfo && userInfo.renownInfo.iconUrl"
          :icon-url="userInfo.renownInfo.iconUrl"
          class="label"
        />
        <!-- 在线状态 -->
        <OnlineStatus
          v-if="userInfo"
          :status="userInfo.onlineStatus"
          :game-code="userInfo.extend && userInfo.extend.teamGameCode"
          class="label"
        />
      </span>
      <span class="info ui-nowrap">
        <template>{{ desc }}</template>
      </span>
    </section>
    <Button
      v-if="!isSamePerson"
      :size="'m'"
      :disabled="!!userInfo.hasInvited"
      :clickable="true"
      @click="$checkAuthDeco(postInvite);onInviteButtonClickReport();"
    >
      {{ btnText }}
    </Button>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import LabelAuthorRank from '@/ui/label/author-rank.vue';
import PrestigeIcon from '@/ui/label/prestige-icon.vue';
import BadgeIcon from '@/ui/label/badge-icon.vue';
import Avatar from '@/ui/avatar/index.vue';
import Button from '@/ui/button/index.vue';
import OnlineStatus from '@/ui/label/online-status.vue';
// import reportApi from '@/api/report';

export default {
  components: {
    BadgeIcon,
    Avatar,
    LabelAuthorRank,
    Button,
    PrestigeIcon,
    OnlineStatus,
  },
  inject: ['reportData'],
  props: {
    userInfo: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
    // userInfo.uid: {
    //   type: [String, Number],
    //   default: '',
    // },
    // userInfo.avatar: {
    //   type: String,
    //   default: '',
    // },
    // nickname: {
    //   type: [String, Number],
    //   default: '',
    // },
    // userLevel: {
    //   type: Number,
    //   default: 1,
    // },
    // hasInvited: {
    //   type: Boolean,
    //   default: false,
    // },
    // accountType: {
    //   type: Number,
    //   default: 0,
    // },
    // vipType: {
    //   type: Number,
    //   default: 0,
    // },
    // desc: {
    //   type: String,
    //   default: '',
    // },
    // schemeUrl: {
    //   type: String,
    //   default: '',
    // },
  },
  data() {
    return {
      //
    };
  },
  computed: {
    ...mapState({
      isApp: state => state.isApp,
      myUserInfo: state => state.user.userInfo,
    }),
    btnType() {
      return this.userInfo.hasInvited ? 'disabled' : 'primary';
    },
    btnText() {
      return this.userInfo.hasInvited ? '已邀请' : '邀请';
    },
    isSamePerson() {
      return this.myUserInfo && this.myUserInfo.uid && this.userInfo.uid && (this.myUserInfo.uid === this.userInfo.uid);
    },
    desc() {
      return this.userInfo.reason || '';
    },
    isShowLabelAuthorRank() {
      const { userInfo } = this;
      const condition1 = (Number(userInfo.accountType) > 0 && Number(userInfo.vipType) !== 1);
      const condition2 = (condition1 || Number(userInfo.accountType) <= 0);
      return condition2 && Number(userInfo.level) > 0 && (!userInfo.userBadge || !userInfo.userBadge.isShow);
    },
  },
  mounted() {
    this.onInviteButtonExposure();
  },
  methods: {
    postInvite() {
      if (this.userInfo.hasInvited) return;
      this.$emit('invite');
    },
    avatarClickReport() {
      // TODO 上报
    },
    onInviteButtonClickReport() {
      if (this.reportData) {
        this.reportData({
          moduleid: 20,
          eventtype: 3,
          extendid: 2,
          indexid: this.index + 1,
        }, '邀请回答按钮点击');
      }
    },
    onInviteButtonExposure() {
      if (this.reportData) {
        this.reportData({
          moduleid: 20,
          eventtype: 2,
          extendid: 1,
          indexid: this.index + 1,
        }, '邀请回答按钮曝光');
      }
    },
  },
};
</script>
<style lang="scss" src="./scss/index.scss"></style>
