<template>
  <div
    ref="poptip"
    class="ui-poptip"
  >
    <div
      @click="handlerTargetClick"
      class="ui-poptip-target"
      tabindex="100"
    >
      <slot />
    </div>
    <div
      v-if="show"
      :class="['ui-poptip-popper', position]"
    >
      <div class="ui-poptip-popper-arrow" />
      <div
        ref="contentEl"
        class="ui-poptip-popper-content"
      >
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Poptip',
  model: {
    prop: 'show',
    event: 'change',
  },
  props: {
    position: {
      type: String,
      default: 'bottom-right',
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      targetEl: null,
    };
  },
  watch: {
    show(val) {
      if (val) {
        document.addEventListener('touchstart', this.handlerTouchEvent);
      } else {
        document.removeEventListener('touchstart', this.handlerTouchEvent);
      }
    },
  },
  mounted() {
    this.targetEl = this.$refs.poptip;
  },
  beforeDestroy() {
    document.removeEventListener('touchstart', this.handlerTouchEvent);
  },
  methods: {
    isTarget(target) {
      const root = document.documentElement || document.body;
      let parent = target;
      while (parent) {
        if (parent === this.targetEl) {
          return true;
        } if (parent === root) {
          return false;
        }
        parent = parent.parentNode;
      }
      return false;
    },
    handlerTargetClick() {
      this.$emit('change', true);
    },
    handlerTouchEvent(e) {
      const target = e.target || e.srcElement;
      if (!this.isTarget(target)) {
        this.$emit('change', false);
      }
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss">
</style>
