<template>
  <div class="ui-status">
    <div
      :class="`error-${status}`"
      class="ui-status__img"
    />
    <slot class="ui-status-main">
      <div class="ui-status__text">
        {{ text }}
      </div>
      <div
        v-if="buttonTitle"
        class="ui-status__button"
      >
        <Button
          :title="buttonTitle"
          @click="$emit('buttonClick')"
          type="secondary"
          width="116px"
          size="l"
        />
      </div>
    </slot>
  </div>
</template>
<script>
import Button from '@/ui/button';

export default {
  name: 'UiStatus',
  components: {
    Button,
  },
  props: {
    text: {
      type: String,
      default: '系统繁忙',
    },
    status: {
      type: String,
      validator: val => ['network', 'fail', 'empty'].indexOf(val) > -1,
      default: 'fail',
    },
    buttonTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      buttonType: '', // 'refresh'：刷新页面, 'back'：返回
      textInner: '',
      statusInner: '',
    };
  },
  computed: {
    //
  },
  created() {
    //
  },
  methods: {
    onRefresh() {
      if (this.refreshUrl) {
        window.location.replace(this.refreshUrl);
      }
    },
  },
};
</script>
<style lang="scss" src="./scss/index.scss"></style>
