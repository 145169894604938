<template>
  <div
    v-if="welfareInfo && welfareInfo.schemaUrl && groupInfo"
    class="com-post-welfare"
  >
    <div
      @click="onShareWelfare"
      class="com-post-welfare-container"
    >
      <div class="com-post-welfare-container__inner">
        <div class="com-post-welfare-container-title">
          <div
            :style="welfareFontStyle"
            class="com-post-welfare-container-title__inner"
          >
            <span>{{ groupInfo.name }}</span>福利站开启啦
          </div>
        </div>
        <div class="com-post-welfare-container-gift">
          <span v-if="giftNumber > 0">{{ giftNumber }}个礼包待领取，网罗全网福利!</span>
          <span v-if="giftNumber <= 0">网罗全网福利，速来!</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import launchApp from '@/util/launchApp';

export default {
  props: {
    welfareInfo: {
      type: Object,
      default() {
        return null;
      },
    },
    groupInfo: {
      type: Object,
      default() {
        return null;
      },
    },
    giftNumber: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      welfareFontStyle: {},
    };
  },
  watch: {
    groupInfo: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.calculateWelfareStyle();
        }
      },
    },
  },
  methods: {
    /**
     * 福利站点击逻辑
     */
    onShareWelfare() {
      launchApp({
        scheme: this.welfareInfo.schemaUrl,
      });
    },
    /**
     * 根据字数，计算福利站样式
     */
    calculateWelfareStyle() {
      const { name } = this.groupInfo;
      const arr = name.length;
      if (arr <= 4) {
        this.welfareFontStyle = {
          transform: 'scale(1)',
        };
      } else if (arr <= 6 && arr > 4) {
        this.welfareFontStyle = {
          transform: 'scale(0.8)',
        };
      } else if (arr <= 8 && arr > 6) {
        this.welfareFontStyle = {
          transform: 'scale(0.7)',
        };
      } else if (arr <= 10 && arr > 8) {
        this.welfareFontStyle = {
          transform: 'scale(0.6)',
        };
      } else {
        this.welfareFontStyle = {
          transform: 'scale(0.55)',
        };
      }
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
