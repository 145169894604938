<template>
  <div
    :class="[`type-${type}`]"
    class="com-group-entry"
  >
    <IconLabel
      v-if="type === 'simple'"
      :pic-url="groupInfo.iconUrl"
      :text="groupInfo.name"
      @click.native="$checkAppDeco(onGroupClickHandler)"
      style="margin-right:4px"
    />
    <template v-else>
      <section
        :style="{backgroundImage: `url(${groupInfo.iconUrl})`}"
        @click="$checkAppDeco(onGroupClickHandler)"
        class="logo"
      />
      <section class="game">
        <span
          @click="$checkAppDeco(onGroupClickHandler)"
          class="nickname"
        >
          <span class="text">{{ groupInfo.name }}</span>
        </span>
        <span class="info">
          <span class="ui-nowrap">{{ groupInfo.latestMessage || '·' }}</span>
        </span>
      </section>
      <Button
        v-if="type==='default'"
        :size="'m'"
        @click="$checkAppDeco(onGroupClickHandler)"
      >
        进圈子
      </Button>
      <div
        v-else-if="type==='light'"
        @click="$checkAppDeco(onGroupClickHandler)"
        class="enter-group-light-btn"
      >
        <span class="enter-group-light-btn-text">
          进圈子
        </span>
        <span class="enter-icon" />
      </div>
    </template>
  </div>
</template>

<script>
import Button from '@/ui/button/index.vue';
import IconLabel from '@/ui/label/icon-label.vue';

export default {
  name: 'GroupEntry',
  components: {
    Button, IconLabel,
  },
  inject: ['reportData'],
  props: {
    /**
     * simple: 小icon加文字，原有圈子入口
     * light: 长条样式不带阴影
     * default: 长条样式带阴影
     */
    type: {
      type: String,
      default: 'default',
      validator: val => ['default', 'light', 'simple'].indexOf(val) > -1,
    },
    groupInfo: {
      type: Object,
      default() {
        return {

        };
      },
    },
  },
  computed: {
    url() {
      return '';
    },
  },
  mounted() {
    this.reportData({
      moduleid: 33,
      eventtype: 2,
      extendid: 1,
      ext2: this.type === 'simple' ? 3 : 2,
    }, '帖子详情页所属圈子曝光');
  },
  methods: {
    onGroupClickHandler() {
      this.$linkToGroup(this.groupInfo.id);
      this.onGroupClickHandlerReport();
    },
    onGroupClickHandlerReport() {
      this.reportData({
        moduleid: 33,
        eventtype: 3,
        extendid: 1,
        ext2: this.type === 'simple' ? 3 : 2,
      }, '帖子详情页所属圈子点击');
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
