<template>
  <div class="pc-has-invite-list">
    <div
      v-for="(item, index) in invitedUserList"
      :key="index"
      class="pc-has-invite-list__item"
    >
      <div class="pc-has-invite-list__nickname">
        {{ item.inviteeUserInfo.nickName }}
      </div>
      <VipIcon
        :vip-type="item.inviteeUserInfo.vipType"
        :account-type="item.inviteeUserInfo.accountType"
        size="l"
      />
      <div
        v-if="Number(item.invitedCnt)===1 && item.inviterUserInfo"
        class="pc-has-invite-list__desc"
      >
        被{{ item.inviterUserInfo.nickName }}邀请来回答
      </div>
      <div
        v-else
        class="pc-has-invite-list__desc"
      >
        被{{ item.invitedCnt | translateNumToText }}人邀请来回答
      </div>
    </div>
  </div>
</template>

<script>
import VipIcon from '@/ui/vip-icon';

export default {
  name: 'PcHasInviteList',
  components: {
    VipIcon,
  },
  props: {
    invitedUserList: {
      type: Array,
      default: () => ([]),
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
