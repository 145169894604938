<template>
  <div
    :style="{width: imageSize.containerWidth, height: imageSize.containerHeight}"
    class="ui-small-image"
  >
    <div
      v-if="loadStatus === 1"
      class="ui-image-fail"
    />
    <div
      v-else-if="loadStatus === 3"
      class="ui-image-blocked"
    />
    <div
      v-else-if="loadStatus !== 2"
      class="ui-image-loading"
    />
    <img
      :width="imageSize.width"
      :height="imageSize.height"
      :src="imageUrl"
      @load="onImageLoad"
      @error="onImageError"
      class="ui-image-img"
    >
  </div>
</template>

<script>
import axios from 'axios';
import clipImageUrl from './cdn-image-clip';

export default {
  name: 'UiSmallImage',
  props: {
    preStatus: {
      type: Number,
      default: 0,
    },
    src: {
      type: String,
      default: '',
    },
    containerWidth: {
      type: [Number, String],
      required: false, // 必须设定外部容器宽度
      default: 0, // 可以不传，那就按宽度进行百分比计算
    },
    containerHeight: {
      type: [Number, String],
      default: 0, // 可以不传，那就按宽度进行百分比计算
    },
    // 图片的真实宽高
    width: {
      type: [Number, String],
      default: 0, // 0 则计算最大宽度
    },
    height: {
      type: [Number, String],
      default: 0, // 0
    },
    mode: { // 模式，contain:完整展示图片、cover:图片裁剪撑满容器
      type: String,
      default: 'contain',
    },
  },
  data() {
    return {
      repeatTimes: 0,
      loadStatus: 0, // 1=加载失败，2=加载成，3=审核失败
    };
  },
  computed: {
    imageSize() {
      const containerWidth = parseInt(this.containerWidth, 10);
      let containerHeight = parseInt(this.containerHeight, 10);
      let width = parseInt(this.width, 10);
      let height = parseInt(this.height, 10);
      // console.log('ui image size', {
      //   containerHeight,
      //   containerWidth,
      //   width,
      //   height,
      // });
      if (!containerWidth) {
        return {
          containerWidth: '100%',
          containerHeight: 'auto',
          width: '100%',
          height: 'auto',
        };
      }
      if (!width || !height) {
        return {
          containerWidth: `${containerWidth}px`,
          containerHeight: 'auto',
          width: '100%',
          height: 'auto',
        };
      }
      const ratio = width / height;
      if (!containerHeight) {
        containerHeight = `${(containerWidth / ratio).toFixed(2)}px`;
        return {
          containerWidth: `${containerWidth}px`,
          containerHeight,
          width: '100%',
          height: 'auto',
          w: containerWidth,
          h: containerWidth / ratio,
        };
      }
      if (this.mode === 'contain') {
        // 下面对应的是有width height containerWidth containerHeight的情况
        if (width <= containerWidth && height <= containerHeight) {
          return {
            containerWidth: `${width}px`,
            containerHeight: `${height}px`,
            width: '100%',
            height: 'auto',
            w: width,
            h: height,
          };
        }
        if (width > containerWidth) {
          height = containerWidth / ratio;
          width = containerWidth;
        }
        if (height > containerHeight) {
          width = containerHeight * ratio;
          height = containerHeight;
        }
        return {
          containerWidth: `${width}px`,
          containerHeight: `${height}px`,
          width: '100%',
          height: 'auto',
          w: width,
          h: height,
        };
      }
      // 覆盖模式
      if (this.mode === 'cover') {
        width = containerWidth;
        height = containerWidth / ratio;
        if (height < containerHeight) {
          height = containerHeight;
          width = containerHeight * ratio;
        }
        return {
          containerWidth: `${containerWidth}px`,
          containerHeight: `${containerHeight}px`,
          width: `${width}px`,
          height: `${height}px`,
          w: width,
          h: height,
        };
      }
      return {
        containerWidth: '100%',
        containerHeight: 'auto',
        width: '100%',
        height: 'auto',
      };
    },
    // 图片裁剪
    imageUrl() {
      const { w: imageWidth } = this.imageSize;
      return clipImageUrl(this.src, imageWidth);
    },
  },
  watch: {
    // imageSize(newVal) {
    //   console.log('imageSize change', newVal);
    // },
  },
  created() {
    // if (this.preStatus !== 2) {
    //   this.loadImage();
    // } else {
    //   this.loadStatus = 2;
    // }
  },
  methods: {
    onImageLoad() {
      this.loadStatus = 2;
    },
    onImageError() {
      let httpUrl = this.imageUrl;
      if (/^tgcimg/.test(httpUrl)) {
        httpUrl = httpUrl.replace('tgcimg:', '');
      }
      axios.get(httpUrl).then(() => {
        //
      })
        .catch((err) => {
          if (err.response && err.response.status === 403) {
            this.loadStatus = 3;
          } else {
            this.loadStatus = 1;
          }
        });
    },
    /**
     * @deprecated
     */
    loadImage() {
      const oImage = new Image();
      oImage.onload = () => {
        this.loadStatus = 2;
        this.$emit('loadComplete', this.src);
      };
      oImage.onerror = () => {
        if (this.repeatTimes === 0) {
          this.repeatTimes += 1;
          this.loadImage();
        } else {
          this.loadStatus = 1;
        }
      };
      oImage.src = this.src;
    },
  },
};
</script>


<style lang="scss">
.ui-small-image {
  position: relative;
  background-color: #f8f8f9;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  .ui-image-fail {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: #f8f8f9 url('./img/img-fail.png') center/47px 62px no-repeat;
  }

  .ui-image-blocked {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: #f8f8f9 url('./img/img-blocked.png') center/86px 73px no-repeat;
  }

  .ui-image-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: #f8f8f9 url('./img/img-loading.png') center/47px 62px no-repeat;
  }

  .ui-image-img {
    margin: 0;
    padding: 0;
    border: none;
  }
}

</style>
