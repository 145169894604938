
/**
 *
 * @param {Object} obj 对象
 * @param {*} keyStr 链式key值，例如 name.first.x
 *
 * @example
 * ```
 * getKeyValue({name: {first: 'bob'}}, 'name.first'); // returns bob
 * ```
 */
export function getKeyValue(obj, keyStr) {
  const keyArr = keyStr.split('.');
  let i = 0;
  let o = obj;
  while (i < keyArr.length - 1) {
    const key = keyArr[i];
    if (o[key] && typeof o[key] === 'object') {
      o = o[key];
      i += 1;
    } else {
      return undefined;
    }
  }
  return o[keyArr[i]];
}

/**
 * 判断两个对象相应的key是否相同
 *
 * @param {Object} obj1 对象1
 * @param {Object} obj2 对象2
 * @param {string} keyStr 链式key值，例如 name.first.x
 * @returns {Boolean}
 */
export function isObjectKeyEqual(obj1, obj2, keyStr) {
  const k1 = getKeyValue(obj1, keyStr);
  const k2 = getKeyValue(obj2, keyStr);
  return k1 === k2 && k1 !== undefined;
}

/**
 * 合并两个数组，去除相同元素
 * 用于列表中拉取更多的时候去重
 * @param {<Arrar.any>} arr1
 * @param {<Arrar.any>} arr2
 * @param {Object} [options] 选项
 * @param {String} [options.key] 数组元素是对象的话，必须传入去重的唯一标识
 *
 * @example
 *   unionList([1,3,4], [2,3,4]); // return [1,3,4,2]
 *   unionList([{id:1}, {id:4}], [{id:2}, {id:1}], {key:'id'}); // return [{id:1},{id:4},{id:2}]
 */
export function unionList(arr1, arr2, options) {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    throw Error('unionList error: 参数必须为数组');
  }
  return arr1.concat(arr2.filter((a) => {
    if (typeof a === 'object') {
      if (options.key) {
        return arr1.every(b => !isObjectKeyEqual(a, b, options.key));
      }
      return true;
    }
    return arr1.indexOf(a) === -1;
  }));
}
