/**
 * 问题模块相关API
 * */
// import GamePlusAPI from '@/util/gameplus-api';
import { gameplusApi } from '@/util/http';

/**
 * 获取问题详情
 * @param {Object} params
 * @param {String} params.postId 问题帖子id
 * @param {String} params.userId 用户uid
 * @param {Number} params.flag 0 默认 1 折叠回答 2 福利开关
 */
export function getQuestionInfoById(params = {}) {
  return gameplusApi.request({
    url: '/community.GCFeedDetailSrv/GetHomePageFeedsDetail',
    data: {
      userId: Number(params.userId),
      feedId: Number(params.postId),
      postType: 2, // 1:普通帖子，2:问题，3:答案
      flag: Number(params.flag) || 0,
    },
    options: {
      errorHandler: false,
      // reqProto: 'GetHomePageFeedsDetailReq',
      // resProto: 'GetHomePageFeedsDetailRsp',
    },
  });
}

/**
 * 问题的关注或者取消关注
 * @param {Object} params
 * @param {}
 */
export function postFollowAction(params = {}) {
  return gameplusApi.request({
    url: '/community.PostSrv/PostAction',
    data: {
      postId: params.postId,
      postType: 2,
      postCreatorId: params.postCreatorId,
      userId: params.userId,
      groupId: params.groupId,
      action: params.action,
    },
  });
}


/**
 * 获取改问题已经被邀请人列表
 * @param {Object} params
 * @param {String} params.questionId 问题帖子id
 * @param {Number} params.page 页码
 * @param {Number} params.pageSize
 */
export function getQuestionInvitedUser(params) {
  return gameplusApi.request({
    url: '/community.PostSrv/GetQuestionInvitedUser',
    data: {
      postId: params.questionId,
      page: params.page,
      pageSize: params.pageSize,
    },
    options: {
      errorHandler: false,
    },
  });
}
