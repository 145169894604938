<template>
  <div class="com-feeds-item">
    <div class="com-feeds-item__top">
      <div class="com-feeds-item__user">
        <FeedsUserInfo
          :user-info="{groupOwnerType: getGroupOwnerType(data.creator, groupInfo),...data.creator}"
          :ip-region="data.postInfo.ipRegionStr || '未知'"
        />
      </div>
    </div>
    <div class="com-feeds-item__main">
      <div :class="`com-feeds-item__content com-feeds-item__content--line8`">
        {{ data.postInfo.summary }}
      </div>
      <div
        v-if="picList.length>0"
        class="com-feeds-item__picture-wrap"
      >
        <div
          v-if="picList.length===1"
          class="com-feeds-item__picture"
        >
          <UiSmallImage
            :container-width="imageContainerWidth"
            :container-height="imageContainerWidth"
            :width="picList[0].width"
            :height="picList[0].height"
            :src="$tgcimg(picList[0].url)"
            mode="contain"
          />
        </div>
        <div
          v-else-if="picList.length===2"
          :style="{width: `${imageContainerWidth*2+8}px`}"
          class="com-feeds-item__picture-more"
        >
          <UiSmallImage
            v-for="(image, picIndex) in picList"
            :key="picIndex"
            :container-width="imageContainerWidth"
            :container-height="imageContainerWidth"
            :width="image.width"
            :height="image.height"
            :src="$tgcimg(image.url)"
            mode="cover"
          />
        </div>
        <div
          v-else
          class="com-feeds-item__picture-more"
        >
          <UiSmallImage
            v-for="(image, picIndex) in picList.slice(0,3)"
            :key="picIndex"
            :container-width="imageContainerWidth"
            :container-height="imageContainerWidth"
            :width="image.width"
            :height="image.height"
            :src="$tgcimg(image.url)"
            mode="cover"
          />
          <div
            v-if="picList.length >= 3"
            class="com-feeds-item__picture-more-tips"
          >
            <span class="com-feeds-item__picture-more-tips--icon" />
            {{ picList.length }}
          </div>
        </div>
      </div>
      <div
        v-if="isOfficialLike"
        class="com-feeds-item__label"
      >
        <Icon
          size="s"
          name="hot-blue"
        />
        <div class="com-feeds-item__label-text">
          <span class="com-feeds-item__label-text-holder">官方推荐</span>
          <span class="com-feeds-item__label-text-show">官方推荐</span>
        </div>
      </div>
    </div>
    <div class="com-feeds-item__bottom">
      <div
        @click.stop="$checkAuthDeco(onShareClick)"
        class="com-feeds-item__action-item"
      >
        <Icon
          size="l"
          name="share-small"
        />
        <span
          v-if="postInfo && postInfo.shareCnt > 0"
          class="com-feeds-item__action-item--text"
        >
          {{ postInfo.shareCnt | translateNumToText }}
        </span>
        <span
          v-else
          class="com-feeds-item__action-item--text"
        >
          分享
        </span>
      </div>
      <div
        @click.stop="$checkAuthDeco(onCommentClick)"
        class="com-feeds-item__action-item"
      >
        <Icon
          size="l"
          name="comment-small"
        />
        <span
          v-if="postInfo && postInfo.commentCnt > 0"
          class="com-feeds-item__action-item--text"
        >
          {{ postInfo.commentCnt | translateNumToText }}
        </span>
        <span
          v-else
          class="com-feeds-item__action-item--text"
        >
          评论
        </span>
      </div>
      <div
        @click.stop="$checkAuthDeco(onPostActionClick)"
        class="com-feeds-item__action-item"
      >
        <template v-if="postAction && postAction.ifLike">
          <Icon
            size="l"
            name="up-small-active"
          />
          <span class="com-feeds-item__action-item--text">已赞同</span>
        </template>
        <template v-else-if="postAction && postAction.ifHappy">
          <Icon
            size="l"
            name="soso-yellow-active"
          />
          <span class="com-feeds-item__action-item--text">已微妙</span>
        </template>
        <template v-else-if="postAction && postAction.ifDislike">
          <Icon
            size="l"
            name="down-small-active"
          />
          <span class="com-feeds-item__action-item--text">已反对</span>
        </template>
        <template v-else>
          <Icon
            size="l"
            name="up-small"
          />
          <span
            v-if="postInfo && postInfo.likeCnt > 0"
            class="com-feeds-item__action-item--text"
          >
            {{ postInfo.likeCnt | translateNumToText }}
          </span>
          <span
            v-else
            class="com-feeds-item__action-item--text"
          >
            赞同
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import FeedsUserInfo from '@/component/feeds-user-info/index.vue';
import UiSmallImage from '@/ui/image/small-image.vue';
import Icon from '@/ui/icon/';
import { showShareDialog } from '@/jsbridge';
import { logger } from '@/util/logger';
import { getShareUrl } from '@/util/get-base-url';
import { isBitTrue } from '@/util/util';
import unicodeString from '@/util/unicode-string';
import { getGroupOwnerType } from '@/business/post/post';

export default {
  name: 'ComFeedsItem',
  components: {
    FeedsUserInfo, UiSmallImage, Icon,
  },
  filters: {
    transNum(num) {
      if (num < 10) {
        return `0${num}`;
      }
      return `${num}`;
    },
  },
  props: {
    data: { // HomePagePost 结构体
      type: Object,
      required: true,
    },
    index: { // 下标，用来上报
      type: Number,
      default: 0,
    },
    groupInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      clientWidth: 375, // 默认先给375，mounted后计算
    };
  },
  computed: {
    picList() {
      try {
        return this.data.postInfo.pics.info;
      } catch (error) {
        logger.error('picList err', error);
        return [];
      }
    },
    imageContainerWidth() {
      const bw = Math.round((this.clientWidth - 36 - 15) / 3); // 单个图片宽度
      if (this.picList.length === 1) {
        return bw + 8;
      } if (this.picList.length > 1) {
        return bw;
      }
      return 224;
    },
    postInfo() {
      try {
        return this.data.postInfo;
      } catch (error) {
        logger.error('postInfo err', error);
        return null;
      }
    },
    postAction() {
      try {
        return this.data.postInfo.extent.userActionWithPost;
      } catch (error) {
        logger.error('postAction err', error);
        return {
          ifLike: 0,
          ifFollow: 0,
          ifAnswer: 0,
          ifDislike: 0,
          ifHappy: 0,
          likeTime: 0,
          dislikeTime: 0,
          happyTime: 0,
          answerId: 0,
        };
      }
    },
    postCoverUrl() {
      const { postInfo } = this;
      const defaultShareCover = 'https://static.gameplus.qq.com/business/Share/iOSAPPicon100.png';
      try {
        return (postInfo
          && postInfo.pics
          && postInfo.pics.info
          && postInfo.pics.info[0]
          && postInfo.pics.info[0].url
        ) || defaultShareCover;
      } catch (error) {
        logger.error('postCoverUrl err', error);
        return defaultShareCover;
      }
    },
    isOfficialLike() {
      // bit10:被官方点赞
      return this.postInfo && this.postInfo.flag && isBitTrue(this.postInfo.flag, 10);
    },
  },
  mounted() {
    this.getClientWidth();
  },
  updated() {
    this.getClientWidth();
  },
  methods: {
    getGroupOwnerType,
    getClientWidth() {
      const clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
      if (this.clientWidth !== clientWidth) {
        this.clientWidth = clientWidth;
      }
    },
    onPostActionClick() {
      const { postAction } = this;
      if (postAction && postAction.ifLike) {
        this.$emit('postAction', 'unlike');
      } else if (postAction && postAction.ifDislike) {
        this.$emit('postAction', 'undislike');
      } else if (postAction && postAction.ifHappy) {
        this.$emit('postAction', 'unhappy');
      } else {
        this.$emit('postAction', 'like');
      }
    },
    onShareClick() {
      const shareContent = {
        shareVisible: this.postInfo.status === 6 ? 1 : 0, // 0 分享不可见， 1 可见
        title: this.postInfo.title || '腾讯游戏社区',
        summary: unicodeString.slice(this.postInfo.summary, 200) || '来自腾讯游戏社区，来闪现，从不落单~',
        targetUrl: getShareUrl(this.postInfo.extent.postDetailUrl),
        thumbUrl: this.postCoverUrl,
        postInfo: {
          postId: this.postInfo.id,
          postType: this.postInfo.postType,
          postCreatorId: (this.data.creator && this.data.creator.uid) || 0,
          groupId: this.postInfo.groupId,
          userId: (this.userInfo && this.userInfo.uid) || 0,
        },
      };
      logger.info('showShareDialog', shareContent);
      showShareDialog(shareContent)
        .then((res2) => {
          logger.info('showShareDialog', shareContent, res2);
          if (res2.result === 0) {
            this.postInfo.shareCnt += 1;
            switch (res2.data.type) {
              case 'WXSession':
                this.sendNotice({
                  operate: 'share',
                  data: 'WXSession',
                });
                break;
              case 'WXTimeline':
                this.sendNotice({
                  operate: 'share',
                  data: 'WXTimeline',
                });
                break;
              case 'QQFriend':
                this.sendNotice({
                  operate: 'share',
                  data: 'QQFriend',
                });
                break;
              case 'QQZone':
                this.sendNotice({
                  operate: 'share',
                  data: 'QQZone',
                });
                break;
              default:
                break;
            }
          }
        });
      return true;
    },
    onCommentClick() {
      this.$emit('onComment');
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
