/**
 * 问题模块相关API
 * */
// import inviteListMockData from '@/mock/inviteList.json';
import { gameplusApi } from '@/util/http';

/**
 * 获取答案详情
 * @param {Object} param
 * @param {Number | String} param.answerId 问题帖子id
 * @param {Number | String} param.userId 用户uid
 * @param {Number | String} param.postType 帖子类型
 * @param {Number} param.flag 0 默认 1 折叠回答 2 福利开关
 */
export function getAnswerInfoByAnswerId({
  answerId, userId, postType = 1, flag = 0,
} = {}) {
  return gameplusApi.request({
    url: '/community.GCFeedDetailSrv/GetHomePageFeedsDetail',
    data: {
      userId: Number(userId),
      feedId: Number(answerId),
      postType, // 1:普通帖子，2:问题，3:答案; 这里就传1当做普通帖子拉取
      flag,
    },
    options: {
      errorHandler: false,
      // reqProto: 'GetHomePageFeedsDetailReq',
      // resProto: 'GetHomePageFeedsDetailRsp',
    },
  });
}

/**
 * 获取答案列表
 * @param {Object} params
 */
export function getAnswerListByQuestionId(params) {
  return gameplusApi.request({
    url: '/community.GCFeedDetailSrv/GetHomePageFeedsDetail',
    data: {
      userId: Number(params.userId),
      parentId: Number(params.questionId),
      postType: 3, // 1:普通帖子，2:问题，3:答案
      pageNum: params.pageNum,
      pageSize: params.pageSize,
      sortType: params.sortType,
      flag: params.flag, // 1=折叠回答
    },
    // mock: commentMockData,
    options: {
      errorHandler: false,
      // reqProto: 'GetHomePageFeedsDetailReq',
      // resProto: 'GetHomePageFeedsDetailRsp',
    },
  });
}

/**
 * 获取邀请列表
 * @param {Object} params
 * @param {String} params.userId 邀请者id，当前用户
 * @param {String} params.questionId 问题帖子id
 * @param {Number} params.page 页码
 * @param {Number} params.pageSize
 */
export function getInviteList(params) {
  return gameplusApi.request({
    url: '/community.PostSrv/InviteAnswer',
    data: {
      userId: params.userId,
      postId: params.questionId,
      page: params.page,
      pageSize: params.pageSize,
    },
  });
}

/**
 * 提交邀请
 * @param {Object} params
 * @param {String} params.questionId 问题id
 * @param {String} params.userId 邀请者id，当前用户
 * @param {String} params.invitedUserId 被邀请者id
 */
export function inviteAnswer(params) {
  return gameplusApi.request({
    url: '/community.PostSrv/ConfirmInviteAnswer',
    data: {
      postId: params.questionId,
      userId: params.userId,
      invitedUserId: params.invitedUserId,
    },
  });
}

/**
 * 获取邀请过的人列表
 * @param {Object} params
 * @param {String} params.userId
 * @param {String} params.questionId
 */
export function getMyInviteUsers(params) {
  return gameplusApi.request({
    url: '/community.PostSrv/GetMyInviteUsers',
    data: {
      postId: params.questionId,
      userId: params.userId,
    },
  });
}

/**
 * 删除答案
 * @param {Object} param
 * @param {Number | String} param.postId 答案帖子id
 */
export function deleteAnswer({ postId } = {}) {
  return gameplusApi.request({
    url: '/community.PostSrv/UpdatePost',
    data: {
      id: postId,
      status: 9,
    },
    // mock: {
    //   ret: 0,
    //   msg: 'success',
    // },
  });
}
