<template>
  <div class="com-invite-answer-list">
    <div
      v-if="pageEmpty"
      :class="{'com-invite-answer-list__full-screen': fullScreen}"
      class="com-invite-answer-list__error"
    >
      <Status
        status="empty"
        text="没有可以邀请的人哦~"
      />
    </div>
    <div
      v-else-if="pageError"
      :class="{'com-invite-answer-list__full-screen': fullScreen}"
      class="com-invite-answer-list__error"
    >
      <Status
        @buttonClick="initPageData"
        status="fail"
        text="内容加载失败，换个姿势刷新试试~"
        button-title="刷新一下"
      />
    </div>
    <ScrollView
      v-else
      :loading="inviteListLoading"
      :error="inviteListError"
      :no-more="inviteListEnd"
      @reachBottom="onReachBottomHandler"
      @bottomErrorClick="onBottomErrorClickHandler"
      no-more-text="- The End -"
    >
      <ul class="com-invite-answer-list__list">
        <li
          v-for="(userInfo, index) in inviteList"
          :key="userInfo.uid"
          class="com-invite-answer-list__item"
        >
          <InviteItem
            :index="index"
            :user-info="userInfo"
            @invite="onInviteHandler(userInfo)"
          />
          <div class="com-invite-answer-list__item-border" />
        </li>
      </ul>
    </ScrollView>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ScrollView from '@/ui/scroll-view/index.vue';
import Status from '@/ui/status';
import { getInviteList, inviteAnswer } from '@/api/answer';
import { logger } from '@/util/logger';
import {
  sendGlobalNotification, receiveGlobalNotification,
} from '@/jsbridge';
import InviteItem from './components/invite-item/index.vue';

export default {
  name: 'PCInviteList',
  components: {
    ScrollView, InviteItem, Status,
  },
  inject: ['reportData'],
  props: {
    pageSize: {
      type: Number,
      default: 20,
    },
    showLoadMore: {
      type: Boolean,
      default: true,
    },
    maxSize: { // 超过这个数量则认为已经结束
      type: Number,
      default: -1, // 小于0为不限制
    },
    fullScreen: { // 兼容全屏的时候错误页面
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inviteListLoading: false,
      inviteListError: false,
      inviteListEnd: false,
      inviteList: [],
      pageError: false,
      pageEmpty: false,
      pageNum: 1,
    };
  },
  computed: {
    ...mapState({
      isApp: state => state.isApp,
      userInfo: state => state.user.userInfo,
    }),
  },
  mounted() {
    this.questionId = this.$route.query.questionId;
    if (this.isApp) {
      this.receiveNotice();
      this.$store.dispatch('user/getUserInfo')
        .then(() => {
          this.pageLoading = false;
          if (this.userInfo) {
            this.initPageData();
          } else {
            this.pageError = true;
            this.initPageData();
          }
        })
        .catch((err) => {
          logger.error('获取用户信息失败', err);
          this.pageLoading = false;
          this.pageError = true;
          // console.log(err);
        });
    } else {
      this.initPageData();
    }
    // console.log('inviteAnswerList mounted');
  },
  methods: {
    initPageData() {
      this.inviteListLoading = true;
      this.inviteListError = false;
      this.inviteListEnd = false;
      this.inviteList = [];
      this.pageError = false;
      this.pageEmpty = false;
      this.getInviteList().then(() => {
        this.$emit('hasInit', this.inviteList);
      })
        .catch(() => {
          this.$emit('error', this.inviteList);
        });
    },
    getInviteList() {
      this.inviteListLoading = true;
      return getInviteList({
        userId: this.userInfo ? Number(this.userInfo.uid) : 0,
        questionId: this.questionId,
        page: this.pageNum,
        pageSize: this.pageSize,
      }).then((res) => {
        this.inviteListLoading = false;
        const inviteList = res.userList
          .map((item) => {
            const userInfo = {
              ...item.userInfo,
              ...item.userRelationCnt,
              hasInvited: item.alreadyInvited,
              reason: item.reason,
            };
            return userInfo;
          }).filter(item => this.inviteList.every(u => u.uid !== item.uid));
        if (this.inviteList.length === 0) {
          this.inviteList = inviteList;
        } else {
          this.inviteList = this.inviteList.concat(inviteList);
        }
        if (this.inviteList.length === 0) {
          this.pageEmpty = true;
        }
        if (this.maxSize > 0 && this.inviteList.length >= this.maxSize) {
          this.inviteListEnd = true;
        } else {
          this.inviteListEnd = !!res.ifLast;
        }
        this.pageNum += 1;
      })
        .catch((err) => {
          logger.error('获取邀请列表失败');
          this.inviteListLoading = false;
          if (this.inviteList.length === 0) {
            this.pageError = true;
          } else {
            this.inviteListError = true;
          }
          return Promise.reject(err);
        });
    },
    onReachBottomHandler() {
      if (!this.showLoadMore || this.inviteListEnd) return;
      this.getInviteList();
    },
    onBottomErrorClickHandler() {
      this.inviteListError = false;
      this.getInviteList();
    },
    onInviteHandler(invitedUserInfo) {
      inviteAnswer({
        questionId: this.questionId,
        userId: this.userInfo.uid,
        invitedUserId: invitedUserInfo.uid,
      }).then(() => {
        this.$toast('邀请成功');
        this.$emit('invited', invitedUserInfo);
        // eslint-disable-next-line no-param-reassign
        invitedUserInfo.hasInvited = true;
        this.sendNotice({ userId: invitedUserInfo.uid });
      })
        .catch((err) => {
          logger.error('onInviteHandler error', err);
          if (err && err.data && err.data.msg) {
            this.$toast(err.data.msg);
          } else {
            this.$toast('邀请失败');
          }
        });
    },
    sendNotice({ userId }) {
      if (this.$compareVersion('1.3') >= 0) {
        // 1.3版本支持全局通信
        sendGlobalNotification('g_inviteAnswer', {
          userId: Number(userId),
        });
        logger.info('sendGlobalNotification g_inviteAnswer success', { userId: Number(this.uid) });
      }
    },
    receiveNotice() {
      if (this.$compareVersion('1.3') >= 0) {
        receiveGlobalNotification('g_inviteAnswer', (params) => {
          // this.$toast(`receive GlobalNotification success${JSON.stringify(params)}`);
          for (let i = 0; i < this.inviteList.length; i++) {
            const userInfo = this.inviteList[i];
            if (Number(params.userId) === Number(userInfo.uid)) {
              userInfo.hasInvited = true;
              logger.info('receive GlobalNotification g_inviteAnswer success', params);
            }
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
