<template>
  <div
    v-if="Number(vipType)===1"
    :class="`ui-vip-icon__${iconDecoType} ui-vip-icon__size-${size} ui-vip-icon__shape-${shape}`"
    class="ui-vip-icon"
  />
</template>

<script>
export default {
  name: 'UiVipIcon',
  props: {
    size: {
      type: String,
      default: 'l',
    },
    vipType: {
      type: Number,
      default: 0,
    },
    accountType: {
      type: Number,
      required: true,
    },
    shape: {
      type: String,
      default: 'round', // round:圆形, stroke:带描边的圆形
    },
  },
  computed: {
    iconDecoType() {
      if (Number(this.vipType) === 1) {
        return {
          1: 'icon-v-blue', // 官方一级账号
          2: 'icon-v-blue', // 官方二级账号
          3: 'icon-star-blue', // 官方个人账号
          4: 'icon-v-orange', // 媒体企业
          5: 'icon-star-orange', // 媒体个人
        }[Number(this.accountType)] || '';
      }
      return '';
    },
  },
};
</script>

<style lang="scss">
.ui-vip-icon {
  display: inline-block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &__size-l {
    width: 13px;
    height: 13px;
  }

  &__icon-v-orange {
    background-image: url("./img/icon_26_redV.png");
  }

  &__icon-v-blue {
    background-image: url("./img/icon_26_blueV.png");
  }

  &__icon-star-orange {
    background-image: url("./img/icon_26_redstar.png");
  }

  &__icon-star-blue {
    background-image: url("./img/icon_26_bluestar.png");
  }

  // 带描边的图标，放在头像里面
  &__icon-v-orange.shape-stroke {
    background-image: url("./img/icon-v-orange.png");
  }

  &__icon-v-blue.shape-stroke {
    background-image: url("./img/icon-v-blue.png");
  }

  &__icon-star-orange.shape-stroke {
    background-image: url("./img/icon-star-orange.png");
  }

  &__icon-star-blue.shape-stroke {
    background-image: url("./img/icon-star-blue.png");
  }
}
</style>
